import {
  cannedResponses,
  updateOrDeleteCannedMessage,
  userDetails,
} from '@/constants/endpoints';

import { APIClient } from '@/lib/ApiClient';

const apiClient = new APIClient();

export function fetchUserDetails() {
  return apiClient.get(userDetails, null, true);
}

export function fetchCannedMessages() {
  return apiClient.get(cannedResponses, null, true);
}

export function createCannedMessage(data) {
  return apiClient.create(
    cannedResponses,
    {
      shortcut: data.shortcut,
      message: data.message,
    },
    true
  );
}

export function updateCannedMessage(cannedResponseId, data) {
  return apiClient.update(
    updateOrDeleteCannedMessage(cannedResponseId),
    {
      shortcut: data.shortcut,
      message: data.message,
    },
    true
  );
}

export function deleteCannedMessage(cannedResponseId) {
  return apiClient.delete(
    updateOrDeleteCannedMessage(cannedResponseId),
    null,
    true
  );
}
