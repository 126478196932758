import { useState } from 'react';
import useUserStore from '@/stores/UserStore';
import { useShallow } from 'zustand/react/shallow';

import { Button } from '../ui/button';
import { Separator } from '../ui/separator';
import CannedMessageTable from './canned-messages/CannedMessageTable';
import CreateShortcutDialog from './canned-messages/CreateShortcutDialog';

const CannedMessages = () => {
  const [open, setOpen] = useState(false);
  const { cannedResponses } = useUserStore(
    useShallow((state) => ({
      cannedResponses: state.cannedResponses,
    }))
  );

  return (
    <div className='space-y-6'>
      <div className='flex justify-between'>
        <div>
          <h3 className='text-xl font-medium'>Saved Replies</h3>
          <p className='text-sm text-muted-foreground'>Create saved replies</p>
        </div>
        <div className='flex flex-row space-x-2'>
          <Button onClick={() => setOpen(true)}>New Saved Reply</Button>
        </div>
      </div>
      <CreateShortcutDialog open={open} setOpen={setOpen} />
      <Separator />
      <CannedMessageTable cannedResponses={cannedResponses} />
    </div>
  );
};

export default CannedMessages;
