import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { fetchAgents } from '@/hooks/agent';

const useTeamMemberStore = create(
  devtools(
    (set) => ({
      agents: [],
      setAgents: (agents) => set({ agents }),

      companyGoal: null,
      setCompanyGoal: (companyGoal) => set({ companyGoal }),
      updateCompanyGoal: (goalAmount) =>
        set((state) => ({
          companyGoal: { ...state.companyGoal, amount: goalAmount },
        })),
      agentGoals: [],
      setAgentGoals: (agentGoals) => set({ agentGoals }),
      addAgent: (agent) =>
        set((state) => ({ agents: [...state.agents, agent] })),
      updateAgentGoalAmount: (updates) =>
        set((state) => ({
          agentGoals: state.agentGoals.map((agent) => {
            const update = updates.find((u) => u.agentId === agent._id);
            return update
              ? { ...agent, goal: { ...agent.goal, amount: update.goal } }
              : agent;
          }),
        })),
      createAgentModalStatus: false,
      setCreateAgentModalStatus: (createAgentModalStatus) =>
        set({ createAgentModalStatus }),

      loading: false,
      hasErrors: false,
      fetchAgents: async () => {
        set({ loading: true });

        try {
          const response = await fetchAgents();
          set({ agents: response, loading: false });
        } catch (err) {
          console.error('Failed to fetch agents:', err);
          set(() => ({ hasErrors: true, loading: false }));
        }
      },

      updateAgentsLeadAllocation: (allocationData) => {
        set((state) => ({
          agents: state.agents.map((agent) => {
            const allocation = allocationData.find(
              (data) => data.user_id === agent._id
            );
            if (allocation) {
              return {
                ...agent,
                lead_allocation_percentage: allocation.percentage,
              };
            }
            return agent;
          }),
        }));
      },
      removeAgent: (agentId) => {
        set((state) => ({
          agents: state.agents.filter((agent) => agent._id !== agentId),
        }));
      },
    }),
    { name: 'TeamMemberStore' }
  )
);

export default useTeamMemberStore;
