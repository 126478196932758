import { Outlet } from 'react-router-dom';

const ReportsLayout = () => {
  return (
    <>
      <div className='hidden h-svh  space-y-6 md:block'>
        <div className='flex h-full flex-col  space-y-8 lg:flex-row lg:space-x-4 lg:space-y-0'>
          <div className='flex-1'>
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportsLayout;
