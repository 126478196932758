import {
  planCreate,
  planDelete,
  planList,
  planUpdate,
} from '@/constants/endpoints';

import { APIClient } from '@/lib/ApiClient';

const apiClient = new APIClient();

export function fetchPlans() {
  return apiClient.get(planList, null, true);
}

export function createPlan(name, amount) {
  return apiClient.create(planCreate, { name, amount }, true);
}

export function deletePlan(id) {
  return apiClient.delete(planDelete(id), null, true);
}

export function updatePlan(id, name, amount) {
  // TODO: update request should be patch and plan_id should id or vice versa
  return apiClient.create(planUpdate, { plan_id: id, name, amount }, true);
}
