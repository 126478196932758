import { useEffect, useState } from 'react';

import { fetchDashboardData } from '@/hooks/report';

import { DateRangePicker } from '../common/date-range-picker/data-range-picker';
import FunnelChart from './charts/Funnel';
import RevenueChart from './charts/Revenue';
import SalesLeaderBoard from './charts/SalesLeaderBoard';
import GoalDialog from './GoalDialog';

const HighchartsComponent = () => {
  const [date, setDate] = useState({});
  const [loading, setLoading] = useState(true);
  const [type, setType] = useState('pre-defined');
  const [value, setValue] = useState('month-to-date');

  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const result = await fetchDashboardData(type, value);
      // const result = {
      //   data: {
      //     date_ranges: {
      //       current: {
      //         start: '2025-02-01',
      //         end: '2025-02-06',
      //       },
      //       previous: {
      //         start: '2025-01-01',
      //         end: '2025-01-31',
      //       },
      //     },
      //     revenue_chart: {
      //       current_value: 100000,
      //       daily_goal: 100000,
      //       goal: 0,
      //     },
      //     funnel_pipeline: {
      //       current_value: [292, 7, 4, 0],
      //       previous_value: [2888, 194, 18, 3],
      //     },
      //     sales_leaderboard: {
      //       value: [],
      //     },
      //   },
      // };
      setData(result.data);
      setDate(result.data.date_ranges);
      setLoading(false);
    };

    fetchData();
  }, [value, type]);

  const updateDateFilter = (values) => {
    const { range, selectedPreset } = values;

    if (selectedPreset === 'custom') {
      setValue([
        range.from.toLocaleDateString().replace(/\//g, '-'),
        range.to.toLocaleDateString().replace(/\//g, '-'),
      ]);

      setType('custom');
    } else {
      setValue(selectedPreset);
      setType('pre-defined');
    }
  };

  return (
    <div className='flex h-full w-full flex-col space-y-2 sm:p-2 lg:p-4 2xl:p-8'>
      <div className='flex items-center justify-between'>
        <div className='flex items-center text-2xl font-semibold'>
          Dashboard
          <span className='ml-2  text-sm text-gray-500'>
            ({date.current?.start} - {date.current?.end})
          </span>
        </div>
        <div className='flex flex-row items-center gap-2'>
          <GoalDialog />
          <DateRangePicker
            defaultPreset={value}
            onUpdate={(values) => updateDateFilter(values)}
            align='end'
            locale='en-US'
            showCompare={false}
          />
        </div>
      </div>

      <div className='flex h-full w-full gap-2 overflow-auto'>
        <div className='flex h-full w-1/3 flex-col gap-2'>
          <div>
            {loading ? (
              <div className='flex h-[200px]  items-center justify-center rounded-lg border'>
                <div className='animate-pulse'>Loading...</div>
              </div>
            ) : data?.revenue_chart.current_value ||
              data?.revenue_chart.goal ? (
              <RevenueChart
                value={data?.revenue_chart.current_value}
                min={0}
                max={data?.revenue_chart.goal}
                dailyGoal={data?.revenue_chart.daily_goal}
              />
            ) : (
              <div className='flex h-[200px]  items-center justify-center rounded-lg border'>
                <div className='animate-pulse'>Please Update Company Goal</div>
              </div>
            )}
          </div>
          <div className='h-full overflow-auto'>
            {loading ? (
              <div className='flex h-full items-center justify-center rounded-lg border'>
                <div className='animate-pulse'>Loading...</div>
              </div>
            ) : (
              <SalesLeaderBoard
                SalesLeaderBoardData={data?.sales_leaderboard.value || []}
              />
            )}
          </div>
        </div>
        <div className='h-full w-full'>
          {loading ? (
            <div className='flex h-[300px] items-center justify-center rounded-lg border'>
              <div className='animate-pulse'>Loading...</div>
            </div>
          ) : (
            <FunnelChart
              previous={data?.funnel_pipeline.previous_value}
              current={data?.funnel_pipeline.current_value}
              currentDateRange={date.current}
              previousDateRange={date.previous}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default HighchartsComponent;
