import { useEffect, useRef, useState } from 'react';
import { MessageSquareText, Plus, Search } from 'lucide-react';

import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { ScrollArea } from '@/components/ui/scroll-area';
import TooltipComponent from '@/components/common/tooltip/tooltip';
import CreateShortcutDialog from '@/components/settings/canned-messages/CreateShortcutDialog';

const CannedMessagesPopover = ({
  shortcuts,
  onSelect,
  processMessage,
  open,
  setOpen,
  textareaRef,
}) => {
  const [search, setSearch] = useState('');
  const [isCreateShortcutDialogOpen, setIsCreateShortcutDialogOpen] =
    useState(false);
  const [selectedShortcut, setSelectedShortcut] = useState(null);
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const listRef = useRef(null);

  const filteredShortcuts = shortcuts.filter(
    (shortcut) =>
      shortcut.shortcut.toLowerCase().includes(search.toLowerCase()) ||
      processMessage(shortcut.message)
        .toLowerCase()
        .includes(search.toLowerCase())
  );

  // Reset focus when search changes
  useEffect(() => {
    setFocusedIndex(-1);
  }, [search]);

  // Update preview when navigating with keyboard
  useEffect(() => {
    if (focusedIndex >= 0 && focusedIndex < filteredShortcuts.length) {
      setSelectedShortcut(filteredShortcuts[focusedIndex]);
    }
  }, [focusedIndex, filteredShortcuts]);

  const handleKeyDown = (e) => {
    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setFocusedIndex((prev) =>
          prev < filteredShortcuts.length - 1 ? prev + 1 : prev
        );
        break;
      case 'ArrowUp':
        e.preventDefault();
        setFocusedIndex((prev) => (prev > 0 ? prev - 1 : prev));
        break;
      case 'Enter':
        e.preventDefault();
        if (focusedIndex >= 0 && focusedIndex < filteredShortcuts.length) {
          const shortcut = filteredShortcuts[focusedIndex];
          onSelect(processMessage(shortcut.message));
        }
        break;
      case 'Escape':
        e.preventDefault();
        setOpen(false);
        textareaRef.current?.focus();
        break;
    }
  };

  // Scroll focused item into view
  useEffect(() => {
    if (focusedIndex >= 0 && listRef.current) {
      const element = listRef.current.querySelector(
        `[data-index="${focusedIndex}"]`
      );
      if (element) {
        element.scrollIntoView({ block: 'nearest' });
      }
    }
  }, [focusedIndex]);

  const renderContent = (currentContent) => {
    if (!currentContent?.content) return null;

    return (
      <>
        {currentContent.content.map((node, nodeIndex) => {
          if (node.type === 'paragraph') {
            // Handle empty paragraph
            if (!node.content || node.content.length === 0) {
              return <br key={nodeIndex} />;
            }

            return (
              <div key={nodeIndex}>
                {node.content?.map((item, itemIndex) => {
                  if (item.type === 'text') {
                    return <span key={itemIndex}>{item.text}</span>;
                  } else if (item.type === 'mention') {
                    return (
                      <code
                        key={itemIndex}
                        className='relative rounded-md bg-muted px-[0.3rem] py-[0.2rem] font-mono text-sm font-semibold'
                      >
                        {item.attrs.id}
                      </code>
                    );
                  } else if (item.type === 'hardBreak') {
                    return <br key={itemIndex} />;
                  }
                  return null;
                })}
              </div>
            );
          }
          return null;
        })}
      </>
    );
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <TooltipComponent
        content={
          <div className='flex space-x-2'>
            <div>Saved Replies</div>
            <div className='flex space-x-1'>
              <kbd className='pointer-events-none hidden h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium opacity-100 sm:flex'>
                {navigator.platform.toLowerCase().includes('mac')
                  ? 'Option'
                  : 'Alt'
                }
              </kbd>
              <kbd className='pointer-events-none hidden h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium opacity-100 sm:flex'>
               S
              </kbd>
            </div>
          </div>
        }
      >
        <PopoverTrigger asChild>
          <Button size='xs' variant='ghost'>
            <MessageSquareText className='h-5 w-5' />
          </Button>
        </PopoverTrigger>
      </TooltipComponent>
      <PopoverContent className='w-[600px] p-0' align='start'>
        <div className='flex items-center gap-2 border-b p-2'>
          <Search className='h-4 w-4 text-muted-foreground' />
          <Input
            placeholder='Search shortcuts or messages...'
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className='h-8 rounded-none border-none focus-visible:ring-0'
            onKeyDown={handleKeyDown}
          />
          <Button
            size='sm'
            variant='outline'
            onClick={() => {
              setIsCreateShortcutDialogOpen(true);
            }}
          >
            <Plus className='h-4 w-4' />
          </Button>
        </div>
        <div className='flex h-[400px]'>
          <div className='w-1/2 border-r'>
            <ScrollArea className='h-full' ref={listRef}>
              <div className='divide-y divide-border'>
                {filteredShortcuts.map((shortcut, index) => (
                  <button
                    key={shortcut.shortcut}
                    data-index={index}
                    onClick={() => {
                      setSelectedShortcut(shortcut);
                      onSelect(processMessage(shortcut.message));
                    }}
                    onMouseEnter={() => setFocusedIndex(index)}
                    className={cn(
                      'w-full px-4 py-2 text-left hover:bg-muted',
                      (selectedShortcut?.shortcut === shortcut.shortcut ||
                        focusedIndex === index) &&
                        'bg-muted',
                      focusedIndex === index && 'ring-1 ring-inset ring-primary'
                    )}
                  >
                    <div className='font-medium'>{shortcut.shortcut}</div>
                  </button>
                ))}
              </div>
            </ScrollArea>
          </div>
          <div className='w-1/2'>
            <ScrollArea className='h-full'>
              <div className='p-4'>
                {selectedShortcut ? (
                  <div>
                    <div className='whitespace-pre-wrap'>
                      {renderContent(selectedShortcut.message)}
                    </div>
                  </div>
                ) : (
                  <div className='flex h-full items-center justify-center text-muted-foreground'>
                    Select a shortcut to preview
                  </div>
                )}
              </div>
            </ScrollArea>
          </div>
        </div>
        <CreateShortcutDialog
          open={isCreateShortcutDialogOpen}
          setOpen={setIsCreateShortcutDialogOpen}
        />
      </PopoverContent>
    </Popover>
  );
};

export default CannedMessagesPopover;
