import { useState } from 'react';
import useContactStore from '@/stores/ContactStore';

import { cn } from '@/lib/utils';

const RocketScore = () => {
  const [isHovered, setIsHovered] = useState(false);
  const rocketIndex = useContactStore((state) => state.rocket_selling_index);

  // Add this console.log to debug
  console.log('Rocket Selling Index:', rocketIndex);

  // Function to determine color based on score
  const getColorByScore = (score) => {
    if (score >= 70) return 'rgb(34, 197, 94)'; // green-500
    if (score >= 50) return 'rgb(234, 179, 8)'; // yellow-500 for 50-69%
    return 'rgb(239, 68, 68)'; // red-500 for <50%
  };

  // Extract scores and total score
  const scores = rocketIndex?.scores || {};
  const totalScore = rocketIndex?.total_score || 0;

  // Check if we have any non-zero scores
  const hasValidScores = Object.values(scores).some(score => score?.score > 0);

  // Don't render anything if no valid scores
  if (!hasValidScores) {
    return null;
  }

  // Map the scores to an array for the quadrants in the specified order
  const quadrantScores = [
    {
      score: scores.process_following?.score || 0,
      title: 'Process Following',
      reason: scores.process_following?.reason || '',
    },
    {
      score: scores.question_quality?.score || 0,
      title: 'Question Quality',
      reason: scores.question_quality?.reason || '',
    },
    {
      score: scores.pain_amplification?.score || 0,
      title: 'Pain Amplification',
      reason: scores.pain_amplification?.reason || '',
    },
    {
      score: scores.closing_probability?.score || 0,
      title: 'Closing Probability',
      reason: scores.closing_probability?.reason || '',
    },
  ];

  return (
    <div
      className='group relative'
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className='absolute -inset-2' />

      <div className='flex h-12 w-12 cursor-pointer items-center'>
        <div className='relative h-full w-full'>
          <svg viewBox='0 0 100 100' className='h-full w-full'>
            {/* Outer Circle Quadrants */}
            {/* Top Right Quarter */}
            <path
              d='M50,50 L50,10 A40,40 0 0,1 90,50 Z'
              fill={getColorByScore(quadrantScores[0].score)}
            />
            {/* Top Left Quarter */}
            <path
              d='M50,50 L10,50 A40,40 0 0,1 50,10 Z'
              fill={getColorByScore(quadrantScores[1].score)}
            />
            {/* Bottom Right Quarter */}
            <path
              d='M50,50 L90,50 A40,40 0 0,1 50,90 Z'
              fill={getColorByScore(quadrantScores[2].score)}
            />
            {/* Bottom Left Quarter */}
            <path
              d='M50,50 L50,90 A40,40 0 0,1 10,50 Z'
              fill={getColorByScore(quadrantScores[3].score)}
            />

            {/* Inner Circle */}
            <circle cx='50' cy='50' r='30' fill='#CCECF5' />
          </svg>
          {/* Center Score */}
          <div className='absolute inset-0 flex items-center justify-center'>
            <span className='text-sm font-bold text-gray-900'>
              {totalScore}
            </span>
          </div>
        </div>
      </div>

      {/* Tooltip with title */}
      {isHovered && (
        <div 
          className='absolute z-50 bg-background rounded-lg p-3 min-w-[320px] text-sm
          border border-border shadow-[0_4px_20px_rgba(0,0,0,0.3)] dark:shadow-[0_4px_20px_rgba(0,0,0,0.5)]'
          style={{
            top: 'calc(100% + 8px)',
            left: '50%',
            transform: 'translateX(-50%)',
          }}
        >
          <h1 className='text-foreground font-bold mb-3 pb-2 border-b flex items-center justify-between'>
            <span>Rocket Selling Index</span>
            <span>{totalScore}/100</span>
          </h1>

          {/* Only show scores > 0 */}
          {quadrantScores
            .filter(metric => metric.score > 0)
            .map((metric, index) => (
              <div key={index} className='mb-3 last:mb-0'>
                <h2 className='flex items-center justify-between text-sm'>
                  <span className='text-foreground font-semibold'>{metric.title}</span>
                  <span className={cn(
                    'font-bold',
                    metric.score >= 70 ? 'text-green-500' :
                    metric.score >= 50 ? 'text-yellow-500' :
                    'text-red-500'
                  )}>
                    {metric.score}%
                  </span>
                </h2>
                <p className='text-muted-foreground text-xs mt-0.5'>{metric.reason}</p>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default RocketScore;
