import { useEffect, useState } from 'react';
import useTeamMemberStore from '@/stores/TeamMemberStore';
import useUserStore from '@/stores/UserStore';
import { MoreHorizontal } from 'lucide-react';

import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';

import { DataTable } from '../common/table/data-table';
import { DataTableColumnHeader } from '../common/table/data-table-column-header';
import { Button } from '../ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu';
import { Separator } from '../ui/separator';
import CreateAgentModal from './team-members/create-agent';
import DeleteAgentDialog from './team-members/DeleteAgentDialog';
import LeadAllocationDialog from './team-members/LeadAllocationDialog';

const TeamMembers = () => {
  const user = useUserStore((state) => state?.user);
  const baseColumns = [
    {
      accessorKey: 'name',
      id: 'name',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title='Name' />
      ),
      cell: ({ row }) => {
        const item = row.original;
        return (
          <div className='flex items-center gap-3'>
            <Avatar>
              <AvatarImage src={item.profile_picture_url} />
              <AvatarFallback>
                {item.first_name.charAt(0)}
                {item.last_name.charAt(0)}
              </AvatarFallback>
            </Avatar>
            <div className='mb-1 text-sm font-medium'>
              {item.first_name} {item.last_name}
            </div>
          </div>
        );
      },
    },
    {
      accessorKey: 'email',
      id: 'email',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title='Email' />
      ),
      cell: (record) => (
        <div>
          <div className='mb-1 text-sm font-medium'>{record.getValue()}</div>
        </div>
      ),
    },
    {
      accessorKey: 'lead_allocation_percentage',
      id: 'lead_allocation_percentage',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title='Lead Allocation' />
      ),
      cell: (record) => (
        <div>
          <div className='mb-1 text-sm font-medium'>
            {record.getValue('lead_allocation_percentage')}
          </div>
        </div>
      ),
    },
    {
      accessorKey: 'role',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title='Role' />
      ),
      cell: ({ row }) => {
        return <> {row.getValue('role')} </>;
      },
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
  ];
  const adminColumns = [
    {
      id: 'actions',
      cell: ({ row }) => {
        const item = row.original;
        return item.role !== 'deleted' && item._id !== user?._id ? (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant='ghost' className='h-8 w-8 p-0'>
                <span className='sr-only'>Open menu</span>
                <MoreHorizontal className='h-4 w-4' />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align='end'>
              <DropdownMenuItem
                onClick={() => {
                  setCurrentAgent(item);
                  setDeleteAgentModalStatus(true);
                }}
              >
                Delete
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        ) : null;
      },
    },
  ];

  const columns =
    user?.role === 'admin' ? [...baseColumns, ...adminColumns] : baseColumns;

  const { agents, fetchAgents } = useTeamMemberStore((state) => ({
    agents: state.agents,
    fetchAgents: state.fetchAgents,
  }));

  useEffect(() => {
    fetchAgents();
  }, [fetchAgents]);

  const [leadAllocationModalStatus, setLeadAllocationModalStatus] =
    useState(false);
  const [deleteAgentModalStatus, setDeleteAgentModalStatus] = useState(false);
  const [currentAgent, setCurrentAgent] = useState(null);

  return (
    <div className='space-y-6'>
      <div className='flex justify-between'>
        <div>
          <h3 className='text-xl font-medium'>Team Members</h3>
          <p className='text-sm text-muted-foreground'>
            Manage your team members.
          </p>
        </div>
        <div className='flex flex-row space-x-2'>
          {agents && user?.role === 'admin' && (
            <Button
              onClick={() =>
                setLeadAllocationModalStatus(!leadAllocationModalStatus)
              }
            >
              Lead Allocation
            </Button>
          )}
          <Button
            onClick={() =>
              useTeamMemberStore.setState({
                createAgentModalStatus: true,
              })
            }
          >
            Add Agents
          </Button>
        </div>
      </div>
      <LeadAllocationDialog
        leadAllocationModalStatus={leadAllocationModalStatus}
        setLeadAllocationModalStatus={setLeadAllocationModalStatus}
      />
      <Separator />
      <DataTable columns={columns} data={agents} hideTheseColumnsInSm={{}} />

      <CreateAgentModal />

      <DeleteAgentDialog
        deleteAgentModalStatus={deleteAgentModalStatus}
        setDeleteAgentModalStatus={setDeleteAgentModalStatus}
        currentAgent={currentAgent}
      />
    </div>
  );
};

export default TeamMembers;
