import { useCallback, useEffect } from 'react';
import useContactStore from '@/stores/ContactStore';
import useInboxStore from '@/stores/InboxStore';
import { XIcon } from 'lucide-react';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';
import { useShallow } from 'zustand/react/shallow';

import { trackProfileCloseButtonClicked } from '@/lib/analytics-event';
import { cn } from '@/lib/utils';
import { fetchContactDetails, fetchContactMessages } from '@/hooks/contact';

import { Button } from '../ui/button';
import { ScrollArea } from '../ui/scroll-area';
import { Separator } from '../ui/separator';
import ContactHead from './chat/ContactHead';
import ContactMessages from './chat/ContactMessages';
import ContactDetails from './contact-card/ContactDetail';

const ContactChat = () => {
  const { id } = useParams();
  const { contactSidebarShow, setContactSidebarShow } = useInboxStore(
    useShallow((state) => ({
      contactSidebarShow: state.contactSidebarShow,
      setContactSidebarShow: state.setContactSidebarShow,
    }))
  );

  const {
    setContactDetails,
    setMessages,
    changeContact,
    setPage,
    resetCommentThread,
    resetSelectedPrivateReplyMessage,
  } = useContactStore(
    useShallow((state) => ({
      setContactDetails: state.setContactDetails,
      setMessages: state.setMessages,
      changeContact: state.changeContact,
      setPage: state.setPage,
      resetCommentThread: state.resetCommentThread,
      resetSelectedPrivateReplyMessage: state.resetSelectedPrivateReplyMessage,
    }))
  );

  const handleNewResponse = useCallback(
    (messages, page) => {
      setMessages(messages);
      setPage(page);
    },
    [setMessages, setPage]
  );

  const fetchContactData = useCallback(async () => {
    try {
      const response = await fetchContactDetails(id);
      setContactDetails(response);
    } catch (error) {
      toast.error('Unable to fetch contact information');
    }
  }, [id, setContactDetails]);

  const fetchContactMessageData = useCallback(async () => {
    try {
      const contactMessageResponse = await fetchContactMessages(id, 1);
      const { data, next_page_url, current_page } = contactMessageResponse;
      handleNewResponse(data, next_page_url ? current_page + 1 : null);
    } catch (error) {
      toast.error('Unable to fetch contact messages.');
    }
  }, [id, handleNewResponse]);

  const closeContactSidebar = () => {
    trackProfileCloseButtonClicked();
    setContactSidebarShow();
  };

  useEffect(() => {
    changeContact(id);
    fetchContactMessageData();
    fetchContactData();
    resetCommentThread();
    resetSelectedPrivateReplyMessage();
  }, [
    id,
    fetchContactData,
    fetchContactMessageData,
    changeContact,
    resetCommentThread,
    resetSelectedPrivateReplyMessage,
  ]);

  return (
    <div className='flex'>
      <div className={cn('flex max-h-screen min-h-screen flex-grow flex-col')}>
        <ContactHead />
        <ContactMessages />
      </div>
      <div
        className={`${
          contactSidebarShow
            ? 'flex min-w-[380px] max-w-[380px] justify-center'
            : 'hidden'
        }`}
      >
        <Separator orientation={'vertical'} />
        <div className=' flex h-screen w-full flex-col '>
          <ScrollArea>
            <div className='absolute right-0 mx-2 my-1 flex items-center justify-end'>
              <Button variant='ghost' size='xs' onClick={closeContactSidebar}>
                <XIcon className='h-5 w-5' />
              </Button>
            </div>
            <ContactDetails />
          </ScrollArea>
        </div>
      </div>
    </div>
  );
};

export default ContactChat;
