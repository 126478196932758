import { clsx } from 'clsx';
import Cookies from 'js-cookie';
import { twMerge } from 'tailwind-merge';

export function formatBytes(bytes, opts = {}) {
  const { decimals = 0, sizeType = 'normal' } = opts;

  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const accurateSizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB'];
  if (bytes === 0) return '0 Byte';
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return `${(bytes / Math.pow(1024, i)).toFixed(decimals)} ${
    sizeType === 'accurate'
      ? (accurateSizes[i] ?? 'Bytes')
      : (sizes[i] ?? 'Bytes')
  }`;
}

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export const isUserAuthenticated = () => {
  const token = getToken();

  if (!token) {
    console.log('user or token missing');
    return false;
  }

  try {
    // const decoded = jwtDecode(user.token);
    // const currentTime = Date.now() / 1000;
    // if (decoded.exp < currentTime) {
    //   console.warn('access token expired');
    //   return false;

    // Handle Removal of token in cookies and user in Auth store if invalid token
    // } else {
    return true;
    // }
  } catch (e) {
    console.warn('access token expired');
    return false;
  }
};

export const getToken = () => {
  const token = Cookies.get('token');

  return token ? token : null;
};

export const setToken = (token) => {
  Cookies.set('token', token, { expires: 7 });
};

export const removeToken = () => {
  Cookies.remove('token');
};

export const formatNumberWithSuffix = (num) => {
  // Handle undefined, null, or invalid input
  if (num === undefined || num === null || isNaN(num)) {
    return '0';
  }

  const absNum = Math.abs(num);
  if (absNum >= 1.0e9) {
    return (num / 1.0e9).toFixed(1) + 'B';
  } else if (absNum >= 1.0e6) {
    return (num / 1.0e6).toFixed(1) + 'M';
  } else if (absNum >= 1.0e3) {
    return (num / 1.0e3).toFixed(1) + 'K';
  }
  return num.toString();
};
