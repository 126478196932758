import Contacts from '@/pages/Contacts';
import Inbox from '@/pages/Inbox';
import Login from '@/pages/Login';
import Register from '@/pages/Register';
import Reports from '@/pages/Reports';
import Settings from '@/pages/Settings';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
} from 'react-router-dom';

import ContactChat from '@/components/inbox/ContactChat';
import AblyLayout from '@/components/layouts/AblyLayout';
import AuthLayout from '@/components/layouts/AuthLayout';
import NonAuthLayout from '@/components/layouts/NonAuthLayout';
import RootLayout from '@/components/layouts/RootLayout';
import Navbar from '@/components/Navbar/Navbar';
import NotFound from '@/components/NotFound/NotFound';
import AgentActivityChart from '@/components/reports/AgentActivityChart';
import HighchartsComponent from '@/components/reports/Highcharts';
import ToolTipCharts from '@/components/reports/Tooltip';
import CannedMessages from '@/components/settings/CannedMessages';
import Instagram from '@/components/settings/Instagram';
import Plans from '@/components/settings/Plans';
import TeamMembers from '@/components/settings/TeamMembers';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<RootLayout />}>
      <Route index element={<Navigate to='/inbox' replace />} />
      <Route element={<NonAuthLayout />}>
        <Route path='login' element={<Login />} />
        {/* <Route path='register' element={<Register />} /> */}
      </Route>
      <Route element={<AblyLayout />}>
        <Route element={<AuthLayout />}>
          <Route element={<Navbar />}>
            <Route path='inbox' element={<Inbox />}>
              <Route path=':id' element={<ContactChat />} />
            </Route>
            <Route path='contacts' element={<Contacts />} />
            <Route path='agent-activity' element={<AgentActivityChart />} />
            <Route path='reports' element={<Reports />}>
              <Route index element={<Navigate to='dashboard' replace />} />
              <Route path='dashboard' element={<HighchartsComponent />} />
              <Route path='tooltip' element={<ToolTipCharts />} />
            </Route>
            <Route path='settings' element={<Settings />}>
              <Route index element={<Navigate to='team-members' replace />} />
              <Route path='team-members' element={<TeamMembers />} />
              <Route path='plans' element={<Plans />} />
              <Route path='saved-replies' element={<CannedMessages />} />
              <Route path='instagram' element={<Instagram />} />
            </Route>
          </Route>
        </Route>
      </Route>
      <Route path='*' element={<NotFound />} />
    </Route>
  )
);

export default router;
