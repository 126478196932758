import React, { useEffect, useMemo, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import 'highcharts/highcharts-more';
import 'highcharts/modules/streamgraph';

import { useTheme } from '@/context/ThemeProvider';

const FunnelChart = ({
  title = 'Funnel Pipeline',
  previous = [],
  current = [],
  currentDateRange = { start: '1 Dec 24', end: ' 22 Dec 24' },
  previousDateRange = { start: '1 Nov 24', end: '22 Nov 24' },
}) => {
  const [isDarkTheme, setIsDarkTheme] = useState(false);
  const { currentTheme } = useTheme();
  const chartData = {
    previous: previous,
    current: current,
  };

  const seriesData = {
    previous: [
      { y: previous[0] || 0 },
      { y: (previous[0] || 0) * 0.999 },
      { y: previous[1] || 0 },
      { y: (previous[1] || 0) * 0.999 },
      { y: previous[2] || 0 },
      { y: (previous[2] || 0) * 0.999 },
      { y: previous[3] || 0 },
      { y: previous[3] || 0 }, // Adjusted to match the length
      { y: previous[3] || 0 }, // Adjusted to match the length
    ],
    current: [
      { y: current[0] || 0 },
      { y: (current[0] || 0) * 0.999 },
      { y: current[1] || 0 },
      { y: (current[1] || 0) * 0.999 },
      { y: current[2] || 0 },
      { y: (current[2] || 0) * 0.999 },
      { y: current[3] || 0 },
      { y: current[3] || 0 }, // Adjusted to match the length
      { y: current[3] || 0 }, // Adjusted to match the length
    ],
  };

  useEffect(() => {
    // Initial theme check
    setIsDarkTheme(document.documentElement.classList.contains('dark'));

    // Create observer for theme changes
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === 'class') {
          setIsDarkTheme(document.documentElement.classList.contains('dark'));
        }
      });
    });

    // Start observing
    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ['class'],
    });

    // Cleanup
    return () => observer.disconnect();
  }, []);

  const formatNumber = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const calculatePercentageChange = (previous, current) => {
    if (previous === 0) {
      return current > 0 ? '100' : '0';
    }
    return (((current - previous) / previous) * 100).toFixed(0);
  };

  const calculateConversionRate = (value, total) => {
    if (total === 0) return '0';
    const rate = (value / total) * 100;
    return rate % 1 === 0 ? rate.toFixed(0) : rate.toFixed(2);
  };

  const plotBands = chartData.current.map((data, index) => {
    const titles = ['Chat Assigned', 'Leads', 'Offer Presented', 'Sales'];
    const from = index * 2;
    const to = from + 2;
    const percentageChange = calculatePercentageChange(
      chartData.previous[index],
      data
    );
    const conversionRate = calculateConversionRate(data, chartData.current[0]);
    const textColor = percentageChange <= 0 ? '#B91C1C' : '#166534';
    const bgColor = percentageChange <= 0 ? '#FEE2E2' : '#DCFCE7';

    return {
      from,
      to,
      color: 'rgba(0, 255, 0, 0)',
      label: {
        useHTML: true,
        text: `
        <div style='display: flex; flex-direction: column; gap: 4.5rem; font-family: Inter, sans-serif;'>
        <div style='display: flex; flex-direction: column; align-items: center; justify-content: center; gap: 0.5rem;'>
          <div style='font-size: 12px; font-weight: 700; color: #6B7280; margin-bottom: 0.5rem;'>
          ${titles[index]}
          </div>
          <div style='font-size: 20px; font-weight: 400; color: ${
            currentTheme === 'light' ? 'black' : 'white'
          };'>
          ${formatNumber(data)}
          </div>
          <div style='display: flex; align-items: center; gap: 0.25rem;'>
          <div style='font-size: 12px; font-weight: 500; padding: 2px 4px; border-radius: 0.375rem; max-width: 50px; color: ${textColor}; background-color: ${bgColor};'>
          ${percentageChange <= 0 ? '↓' : '↑'} ${Math.abs(percentageChange)}%
          </div>
          </div>
        </div>
        <div style="width: fit-content; border-radius: 9999px; background-color: #AAAAAA95; padding: 0.125rem 0.5rem; color: #FFFFFF;">
          <div style="font-size: 0.875rem; font-weight: 500;">${conversionRate}%</div>
        </div>
        </div>
      `,
        align: 'center',
        y: -30,
      },
    };
  });

  const options = useMemo(
    () => ({
      chart: {
        type: 'streamgraph',
        backgroundColor: 'transparent',
        marginTop: 100,
      },
      title: {
        useHTML: true,
        text: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem; font-family: 'Inter', sans-serif;">
          <div style="font-size: 20px; font-weight: 700; color: ${
            currentTheme === 'light' ? 'black' : 'white'
          };">${title}</div>
        </div>
        `,
      },
      credits: {
        enabled: false,
      },
      legend: {
        events: {
          itemClick: function (e) {
            return false;
          },
        },
      },
      xAxis: {
        labels: {
          enabled: false,
        },
        tickLength: 0,
        lineColor: 'transparent',
        plotBands: plotBands,
        plotLines: [
          {
            value: 2,
            color: isDarkTheme ? '#1e293b' : '#e2e8f0',
            width: 1,
          },
          {
            value: 4,
            color: isDarkTheme ? '#1e293b' : '#e2e8f0',
            width: 1,
          },
          {
            value: 6,
            color: isDarkTheme ? '#1e293b' : '#e2e8f0',
            width: 1,
          },
        ],
      },
      yAxis: {
        visible: false,
      },
      tooltip: {
        shared: true,
        outside: true,
        padding: 10,
        useHTML: true,
        formatter: function () {
          const plotData = [];
          let tooltipTitle = '';
          let currentValue = '';
          let previousValue = '';

          const points = this.points;
          points.forEach((point) => {
            const index = point.index; // Get the index of the current point
            let targetIndex = index;

            // Determine the target index based on whether the current index is even or odd
            if (index % 2 === 1) {
              targetIndex = index - 1; // If odd show the nearest even index before it
            }

            const plotTitle = [
              'Chat Assigned',
              'Leads',
              'Offer Presented',
              'Sales',
            ];

            tooltipTitle =
              targetIndex === point.series.data.length - 1
                ? plotTitle[3]
                : plotTitle[targetIndex / 2];

            currentValue =
              targetIndex === point.series.data.length - 1
                ? chartData.current[3] || 0
                : chartData.current[targetIndex / 2] || 0;

            previousValue =
              targetIndex === point.series.data.length - 1
                ? chartData.previous[3] || 0
                : chartData.previous[targetIndex / 2] || 0;

            // Get the y-value of the target point
            const targetPointValue = point.series.data[targetIndex].y;

            // plotData not needed anymore. now using currentValue and previousValue
            plotData.push(targetPointValue);
          });

          const percentageChange =
            previousValue === 0
              ? currentValue > 0
                ? '100'
                : '0'
              : (
                  ((currentValue - previousValue) / previousValue) *
                  100
                ).toFixed(0);

          const textColor = percentageChange <= 0 ? '#B91C1C' : '#166534';
          const bgColor = percentageChange <= 0 ? '#FEE2E2' : '#DCFCE7';

          return `
            <div style='width:fit-content; border-radius: 0.5rem; background-color: white;  font-family: Inter, sans-serif;'>
              <div style='margin-bottom: 0.75rem; display: flex; flex-direction: column; gap: 0.5rem;'>
              <div style='font-size: 0.875rem; font-weight: 500; color: #60A5FA;'>
                ${tooltipTitle}
              </div>
              <div style='border-radius: 0.375rem; background-color: #E5E7EB; padding: 0.5rem;'>
              <div style='font-size: 0.875rem; color: #9CA3AF;'>Period</div>
              <div style='font-size: 1.25rem; font-weight: 700; color: black;'>
                ${currentValue}
              </div>
              </div>
              <div style='display: flex; flex-direction: column; gap: 0.5rem; background-color: var(--background, white); padding: 0.5rem;'>
              <div style='display: flex; align-items: center; gap: 0.25rem;'>
              <div style='display: flex; align-items: center; border-radius: 0.375rem; background-color: ${bgColor}; padding: 0.125rem 0.5rem; color: ${textColor};'>
                <div style='font-size: 0.875rem; font-weight: 500;'>
                  ${percentageChange <= 0 ? '↓' : '↑'} ${Math.abs(percentageChange)}%
                </div>
              </div>
              <div style='font-size: 0.75rem; color: #9CA3AF;'>
                vs previous period
              </div>
              </div>
              <div style='display: flex; align-items: flex-end; gap: 0.25rem;'>
              <div style='font-size: 1.125rem; font-weight: 600; color: black;'>
               ${previousValue}
              </div>
              <div style='font-size: 0.75rem; font-weight: 700; color: #9CA3AF;'>
                on ${previousDateRange.start} - ${previousDateRange.end}
              </div>
              </div>
              </div>
              </div>
            </div>
            `;
        },
      },
      plotOptions: {
        streamgraph: {
          lineWidth: 0,
          fillOpacity: 0.75,
          marker: {
            enabled: false,
            states: {
              hover: {
                enabled: false,
              },
            },
          },
          trackByArea: true,
          stacking: 'stream',
          lineColor: 'transparent',
          grouping: false,
        },
        series: {
          marker: {
            enabled: false,
            states: {
              hover: {
                enabled: false,
              },
            },
          },
        },
      },
      series: [
        {
          name: 'Previous month',
          data: seriesData.previous,
          color: '#00aaff',
          fillOpacity: 0.2,
          // zIndex: 1,
          stack: 1,
        },
        {
          name: 'This month',
          data: seriesData.current,
          color: '#00aaff',
          fillOpacity: 0.5,
          // zIndex: 0,
          stack: 0,
        },
      ],
    }),
    [title, isDarkTheme, chartData]
  );

  return (
    <div className='light:bg-white  rounded-lg border border-gray-200 dark:border-gray-700'>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default FunnelChart;
