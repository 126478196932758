import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/ui/button';

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div className='container'>
      <div className='flex min-h-screen flex-col items-center justify-center text-center'>
        <h1 className='mb-4 text-6xl font-bold text-primary'>404</h1>
        <h2 className='mb-4 text-3xl font-semibold'>Oops! Page not found</h2>
        <p className='mb-8 text-muted-foreground'>
          The page you&apos;re looking for doesn&apos;t seem to exist
        </p>
        <Button variant='default' size='lg' onClick={() => navigate('/inbox')}>
          Back to Home
        </Button>
      </div>
    </div>
  );
};

export default NotFound;
