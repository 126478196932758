import { useState } from 'react';
import { MoreHorizontal } from 'lucide-react';

import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { ScrollArea } from '@/components/ui/scroll-area';
import { DataTable } from '@/components/common/table/data-table';
import { DataTableColumnHeader } from '@/components/common/table/data-table-column-header';
import DeleteCannedMessageDialog from '@/components/settings/canned-messages/DeleteCannedMessageDialog';

import EditShortcutDialog from './EditShortcutDialog';

const CannedMessageTable = ({ cannedResponses }) => {
  const [deleteModalStatus, setDeleteModalStatus] = useState(false);
  const [shortcutItem, setShortcutItem] = useState(null);

  const [editModalStatus, setEditModalStatus] = useState(false);

  const renderContent = (currentContent) => {
    if (!currentContent?.content) return null;

    return (
      <>
        {currentContent.content.map((node, nodeIndex) => {
          if (node.type === 'paragraph') {
            // Handle empty paragraph
            if (!node.content || node.content.length === 0) {
              return <br key={nodeIndex} />;
            }

            return (
              <div key={nodeIndex}>
                {node.content?.map((item, itemIndex) => {
                  if (item.type === 'text') {
                    return <span key={itemIndex}>{item.text}</span>;
                  } else if (item.type === 'mention') {
                    return (
                      <code
                        key={itemIndex}
                        className='relative rounded-md bg-muted px-[0.3rem] py-[0.2rem] font-mono text-sm font-semibold'
                      >
                        {item.attrs.id}
                      </code>
                    );
                  } else if (item.type === 'hardBreak') {
                    return <br key={itemIndex} />;
                  }
                  return null;
                })}
              </div>
            );
          }
          return null;
        })}
      </>
    );
  };

  const handleDelete = (item) => {
    setShortcutItem(item);
    setDeleteModalStatus(true);
  };

  const handleEdit = (item) => {
    setShortcutItem(item);
    setEditModalStatus(true);
  };

  const columns = [
    {
      accessorKey: 'shortcut',
      id: 'shortcut',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title='Shortcuts' />
      ),
      cell: (record) => (
        <div>
          <div className='mb-1 text-sm font-medium'>{record.getValue()}</div>
        </div>
      ),
    },
    {
      accessorKey: 'message',
      id: 'message',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title='Messages' />
      ),
      cell: (record) => <div> {renderContent(record.getValue())}</div>,
    },
    {
      id: 'actions',
      cell: ({ row }) => {
        const item = row.original;

        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant='ghost' className='h-8 w-8 p-0'>
                <span className='sr-only'>Open menu</span>
                <MoreHorizontal className='h-4 w-4' />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align='end'>
              <DropdownMenuItem
                onClick={() => {
                  handleEdit(item);
                }}
              >
                Edit
              </DropdownMenuItem>
              <DropdownMenuItem onClick={() => handleDelete(item)}>
                Delete
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
  ];

  return (
    <div className='light:bg-white flex h-full w-full flex-col gap-2 overflow-auto rounded-lg border border-gray-200 dark:border-gray-700'>
      <ScrollArea className='h-full w-full overflow-auto'>
        <DataTable
          columns={columns}
          data={cannedResponses}
          hideTheseColumnsInSm={{}}
          pagination={false}
          isBorderEnabled={false}
        />
        <EditShortcutDialog
          item={shortcutItem}
          editModalStatus={editModalStatus}
          setEditModalStatus={setEditModalStatus}
        />
        <DeleteCannedMessageDialog
          item={shortcutItem}
          deleteModalStatus={deleteModalStatus}
          setDeleteModalStatus={setDeleteModalStatus}
        />
      </ScrollArea>
    </div>
  );
};

export default CannedMessageTable;
