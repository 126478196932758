/**
 * Ref:https://jsfiddle.net/9x4dtvyb/
 * Ref:https://jsfiddle.net/3tsrxfq5/1/
 */

import React, { useEffect, useMemo, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import 'highcharts/highcharts-more';
import 'highcharts/modules/solid-gauge';

import { useTheme } from '@/context/ThemeProvider';

import { formatNumberWithSuffix } from '@/lib/utils';

const RevenueChart = ({
  value,
  min,
  max,
  title = 'Revenue',
  symbol = '',
  dailyGoal,
}) => {
  const [isDarkTheme, setIsDarkTheme] = useState(false);
  const { currentTheme } = useTheme();

  let adjustedMax = max ? max : value;
  let arrowValue = value <= adjustedMax ? value : adjustedMax;

  useEffect(() => {
    // Initial theme check
    setIsDarkTheme(document.documentElement.classList.contains('dark'));

    // Create observer for theme changes
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === 'class') {
          setIsDarkTheme(document.documentElement.classList.contains('dark'));
        }
      });
    });

    // Start observing
    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ['class'],
    });

    // Cleanup
    return () => observer.disconnect();
  }, []);

  const calculateLabelPosition = (value, maxValue, radius, axis) => {
    const angle = (value / maxValue) * 180;
    const radians = (angle * Math.PI) / 180;
    const x = radius * Math.cos(radians);
    const y = radius * Math.sin(radians);

    // Add an offset to x position when angle is less than 90 degrees
    if (angle < 90 && axis === 'x') {
      return x + 20; // Adjust the 20 value to control how far right the label moves
    }

    return axis === 'x' ? x : y;
  };

  const options = useMemo(
    () => ({
      chart: {
        type: 'gauge',
        height: '70%',
        backgroundColor: 'transparent',
      },
      credits: {
        enabled: false,
      },

      title: {
        useHTML: true,
        text: `
          <div style="display: flex; flex-direction: column; gap: 0.5rem; font-family: 'Inter', sans-serif;">
          <div style="font-size: 1rem; font-weight: 500; color: ${
            currentTheme === 'light' ? 'black' : 'white'
          };">${title}</div>
          <div style="font-size: 1.5rem; font-weight: 600; color: ${
            currentTheme === 'light' ? 'black' : 'white'
          };">$ ${formatNumberWithSuffix(value)}${symbol}</div>
        </div>
        `,
      },

      pane: {
        startAngle: -90,
        endAngle: 90,
        background: [
          {
            color: null,
            borderColor: null,
            backgroundColor: null,
            innerRadius: '77%',
            outerRadius: '100%',
            shape: 'arc',
          },
        ],
        center: ['50%', '60%'],
        size: '120%',
      },
      yAxis: {
        min: min,
        max: adjustedMax,
        endOnTick: false,
        maxPadding: 0,
        // stops: [
        //   [0.1, '#FEE2E2'], // red
        //   [0.5, '#FBD38D'], // yellow
        //   [0.9, '#D1FAE5'], // green
        // ],
        tickAmount: 0,
        tickPositions: [min, adjustedMax],
        tickWidth: 0,
        // tickPlacement: 'on',
        zIndex: 10,
        minorTickInterval: null,
        labels: {
          distance: '90%',
          align: 'auto',
          style: {
            fontSize: '14px',
            color: currentTheme === 'light' ? '#000' : '#fff',
            fontFamily: 'Inter, sans-serif',
          },
          formatter: function () {
            return `$${formatNumberWithSuffix(this.value)}`;
          },
        },
        lineWidth: 0,
        plotLines: [
          {
            value: dailyGoal,
            color: 'black',
            width: 2,
            zIndex: 10,
            label: {
              text: `$${formatNumberWithSuffix(dailyGoal)}`,
              // x: calculateLabelPosition(100, 100, -10, 'x'),
              // y: calculateLabelPosition(100, 100, -10, 'y'),
              style: {
                color: currentTheme === 'light' ? 'black' : 'white',
                fontWeight: 'bold',
                textOutline: '0.5px contrast',
              },
            },
          },
        ],
        plotBands: [
          {
            from: min,
            to: arrowValue,
            color: currentTheme === 'light' ? '#66CCFF' : '#0169A2',
            thickness: 30,
          },
          {
            from: arrowValue,
            to: adjustedMax,
            color: '#E0CDD6',
            thickness: 30,
          },
        ],
      },
      tooltip: {
        useHTML: true,
        outside: true,
        padding: 10,
        formatter: function () {
          const percentageDifferenceBetweenValueAndMax = adjustedMax
            ? Math.abs(((adjustedMax - value) / adjustedMax) * 100).toFixed(0)
            : '0';
          const percentageDifferenceBetweenValueAndDailyGoal = dailyGoal
            ? Math.abs(((dailyGoal - value) / dailyGoal) * 100).toFixed(0)
            : '0';

          const getArrowAndColors = (currentValue, targetValue) => {
            const arrow = currentValue > targetValue ? '↑' : '↓';
            const bgColor = currentValue > targetValue ? '#D1FAE5' : '#FEE2E2';
            const textColor =
              currentValue > targetValue ? '#065F46' : '#B91C1C';
            return { arrow, bgColor, textColor };
          };

          const {
            arrow: arrowDailyGoal,
            bgColor: bgColorDailyGoal,
            textColor: textColorDailyGoal,
          } = getArrowAndColors(value, dailyGoal);
          const {
            arrow: arrowMax,
            bgColor: bgColorMax,
            textColor: textColorMax,
          } = getArrowAndColors(value, adjustedMax);

          return `
              <div style="width: 18rem; border-radius: 0.5rem; background-color: white; padding: 0px; margin: 0px; max-width: 180px; font-family: 'Inter', sans-serif;">
            <div style="margin-bottom: 0.75rem; display: flex; flex-direction: column; gap: 0.5rem;">
              <div style="font-size: 0.875rem; font-weight: 500; color: #6B7280;">Sales in $</div>

              <div style="border-radius: 0.375rem; background-color: #E5E7EB; padding: 0.5rem;">
              <div style="font-size: 0.875rem; color: #9CA3AF;">Period</div>
              <div style="font-size: 1.25rem; font-weight: 700; color: black;">$ ${formatNumberWithSuffix(
                value
              )}</div>
              </div>

              <div style="display: flex; flex-direction: column; gap: 0.5rem; background-color: white; padding: 0.5rem;">
              <div style="display: flex; align-items: center; gap: 0.25rem;">
                <div style="display: flex; align-items: center; border-radius: 0.375rem; background-color: ${bgColorDailyGoal}; padding: 0.125rem 0.5rem; color: ${textColorDailyGoal};">
                <div style="font-size: 0.875rem; font-weight: 500;">${arrowDailyGoal} ${percentageDifferenceBetweenValueAndDailyGoal}% </div>
                </div>
                <div style="font-size: 0.75rem; color: #9CA3AF;">vs goal</div>
              </div>
              <div style="font-size: 1rem; font-weight: 600; color: black;">$ ${formatNumberWithSuffix(
                dailyGoal
              )}</div>
              </div>
              <div style="height: 0.5px; width: 100%; background-color: #E5E7EB;"></div>
              <div style="display: flex; flex-direction: column; gap: 0.5rem; background-color: white; padding: 0.5rem;">
              <div style="display: flex; align-items: center; gap: 0.25rem;">
                <div style="display: flex; align-items: center; border-radius: 0.375rem; background-color: ${bgColorMax}; padding: 0.125rem 0.5rem; color: ${textColorMax};">
                <div style="font-size: 0.875rem; font-weight: 500;">${arrowMax} ${percentageDifferenceBetweenValueAndMax}% </div>
                </div>
                <div style="font-size: 0.75rem; color: #9CA3AF;">vs goal</div>
              </div>
              <div style="display: flex; align-items: center; gap: 0.25rem;">
                <div style="font-size: 1rem; font-weight: 600; color: black;">$ ${formatNumberWithSuffix(
                  adjustedMax
                )}</div>
                <div style="font-size: 0.75rem; color: #9CA3AF;">on Jan 1 - 22</div>
              </div>
              </div>
            </div>
            </div>
              `;
        },
      },
      series: [
        {
          name: 'Revenue',
          data: [arrowValue],
          tooltip: {
            valueSuffix: ' %',
          },
          dataLabels: {
            enabled: true,
            borderWidth: 0,
            useHTML: true,
            formatter: function () {
              // Calculate percentage of value against maximum, return '0' if max is 0 or falsy
              const percentageOfMax = adjustedMax
                ? ((value / adjustedMax) * 100).toFixed(2)
                : '0';
              const getBackgroundColor = (percentage) => {
                if (percentage < 50) return '#E02424'; // red
                if (percentage < 75) return '#C27803'; // yellow
                return '#057A55'; // green
              };

              const backgroundColor = getBackgroundColor(percentageOfMax);

              return `
                <div style="display: flex; align-items: center; border-radius: 0.375rem; background-color: ${backgroundColor}; padding: 0.125rem 0.5rem; color: white;">
                <div style="font-size: 0.875rem; font-weight: 500;">${percentageOfMax}%</div>
                </div>
                `;
            },

            // y: -25,
          },
          dial: {
            radius: '100%',
            backgroundColor: currentTheme === 'light' ? '#000' : '#fff',
            baseWidth: 12,
            baseLength: '0%',
            rearLength: '0%',
          },
          pivot: {
            backgroundColor: currentTheme === 'light' ? '#000' : '#fff',
            radius: 7,
          },
        },
      ],
    }),
    [title, value, symbol, isDarkTheme, adjustedMax, min, dailyGoal]
  );

  return (
    <div className='light:bg-white rounded-lg border border-gray-200 dark:border-gray-700'>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default RevenueChart;
