import avatarFB from '@/assets/images/avatar-fb.png';
import avatarIG from '@/assets/images/avatar-ig.png';
import useContactStore from '@/stores/ContactStore';
import { Link } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import { trackIGLinkClicked } from '@/lib/analytics-event';
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  AvatarProfile,
} from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';

import CommentThread from './CommentThread';
import ContactOwner from './ContactOwner';
import ContactTags from './ContactTag';
import TipTapEditor from './Notes';

const ContactDetails = () => {
  const {
    fullName,
    profilePictureUrl,
    igUsername,
    plan,
    contactId,
    channel,
    ai_notes,
    followers_count,
    following_count,
    verified,
    bio,
    external_url,
  } = useContactStore(
    useShallow((state) => ({
      fullName: state.fullname,
      profilePictureUrl: state.profile_picture_url,
      igUsername: state.ig_username,
      ai_notes: state.ai_notes,
      plan: state.plan,
      contactId: state._id,
      channel: state.channel,
      followers_count: state.followers_count,
      following_count: state.following_count,
      verified: state.verified,
      bio: state.bio,
      external_url: state.external_url,
    }))
  );

  // Debug log to check values
  console.log('Contact Details:', {
    followers_count,
    following_count,
    verified,
    bio,
  });

  if (!contactId) return null;

  return (
    <div className='flex w-full flex-col space-y-4 overflow-hidden'>
      <div className='flex flex-row items-start justify-start space-x-2 border-b border-borderColor px-4 py-3'>
        <div>
          <Avatar className='h-20 w-20'>
            <AvatarImage
              src={
                profilePictureUrl
                  ? profilePictureUrl
                  : 'https://files.sbccrm.com/sbccrm/sbccrm/contacts/pics/no-pic.jpg'
              }
            />
            <AvatarFallback>{fullName.charAt(0)}</AvatarFallback>
          </Avatar>
        </div>

        <div className='flex w-64 flex-col items-start py-2'>
          <AvatarProfile
            name={fullName}
            username={igUsername}
            channel={channel}
            followers={followers_count || '0'}
            following={following_count || '0'}
            isVerified={verified}
            bio={bio}
            plan={plan}
            external_url={external_url}
          />
        </div>
      </div>

      <ContactTags />

      <ContactOwner />

      <CommentThread />

      <div className='space-y-2 border-b border-borderColor px-4'>
        <div className='text-sm font-medium'>AI Notes</div>
        <div className='space-y-2'>
          <div>
            <AINotes notes={ai_notes} />
          </div>
          <div>
            <TipTapEditor />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactDetails;

const AINotes = ({ notes }) => {
  return (
    notes && (
      <div className='relative rounded-full p-2'>
        <p className='relative z-10 whitespace-pre-line text-[0.85rem] leading-[1.1rem]'>
          {notes}
        </p>
        <div className='mask-gradient absolute inset-0 rounded-md bg-gradient-to-r from-purple-600 to-yellow-500 p-[2px]' />
      </div>
    )
  );
};
