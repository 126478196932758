import { useCallback, useMemo, useRef, useState } from 'react';
import useContactStore from '@/stores/ContactStore';
import useInboxStore from '@/stores/InboxStore';
import useUserStore from '@/stores/UserStore';
import { useHotkeys } from '@blueprintjs/core';
import { SendHorizonalIcon, XIcon } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import { useShallow } from 'zustand/react/shallow';

import {
  trackContactStatusChange,
  trackMessageInputClicked,
  trackQuickCloseContactFeature,
} from '@/lib/analytics-event';
import { cn } from '@/lib/utils';
import { updateContactStatus } from '@/hooks/contact';
import { Button } from '@/components/ui/button';
import { Textarea } from '@/components/ui/textarea';

import CannedMessagesPopover from './Message/CannedMessagePopover';
import FileAttachment from './Message/FileAttachment';

export default function MessageInput({ addMessage }) {
  const [messageType, setMessageType] = useState('text');
  const [message, setMessage] = useState('');
  const [isAttachmentSelected, setIsAttachmentSelected] = useState(false);
  const [isCannedMessagePopoverOpen, setIsCannedMessagePopoverOpen] =
    useState(false);

  const textareaRef = useRef(null);

  const navigate = useNavigate();

  const clientId = useUserStore((state) => state.clientId);
  const {
    conversations,
    removeConversation,
    increaseConversationCount,
    decreaseConversationCount,
    selectedConversationStatus,
  } = useInboxStore(
    useShallow((state) => ({
      conversations: state.conversations,
      removeConversation: state.removeConversation,
      increaseConversationCount: state.increaseConversationCount,
      decreaseConversationCount: state.decreaseConversationCount,
      selectedConversationStatus: state.selectedConversationStatus,
    }))
  );

  const {
    id,
    status,
    channel,
    contactUsername,
    contactFullname,
    selectedPrivateReplyMessage,
    selectedPrivateReplyMessageId,
    resetSelectedPrivateReplyMessage,
  } = useContactStore(
    useShallow((state) => ({
      id: state._id,
      status: state.status,
      channel: state.channel,
      contactUsername: state.ig_username,
      contactFullname: state.fullname,
      selectedPrivateReplyMessage: state.selectedPrivateReplyMessage,
      selectedPrivateReplyMessageId: state.selectedPrivateReplyMessageId,
      resetSelectedPrivateReplyMessage: state.resetSelectedPrivateReplyMessage,
    }))
  );

  const shortcuts = useUserStore((state) => state.cannedResponses);

  const handleMessage = async (e) => {
    e.preventDefault();
    //if text value is not emptry then call onaddMessage function
    if (
      messageType === 'text' &&
      message !== '' &&
      selectedPrivateReplyMessageId === null
    ) {
      // Normal Text Message
      setMessage('');
      return await addMessage(message, 'text');
    } else if (
      messageType === 'text' &&
      message !== '' &&
      selectedPrivateReplyMessageId !== null
    ) {
      // Private DM for Comment
      setMessage('');
      resetSelectedPrivateReplyMessage();
      const options = {
        sbcMessageId: selectedPrivateReplyMessageId,
        commentText: selectedPrivateReplyMessage,
      };
      return await addMessage(message, 'private_reply_comment', options);
    }
  };

  const updateStatus = async () => {
    try {
      const newStatus = status === 'open' ? 'close' : 'open';
      const response = await updateContactStatus(id, newStatus, clientId);
      trackContactStatusChange(newStatus);

      return response;
    } catch (error) {
      toast.dismiss('sending-message');
      toast.error('Unable to update contact status');
    }
  };

  const processMessageContent = (content) => {
    let result = '';

    content.forEach((item) => {
      if (item.type === 'text') {
        result += item.text;
      } else if (item.type === 'mention') {
        if (item.attrs.id === 'first-name') {
          result += contactFullname?.split(' ')[0] || '';
        }
      } else if (item.type === 'hardBreak') {
        result += '\n';
      }
    });

    return result;
  };

  const processMessage = (message) => {
    let result = '';

    message.content.forEach((paragraph) => {
      if (paragraph.content) {
        result += processMessageContent(paragraph.content);
      }
      // Add newline between paragraphs if it's not the last paragraph
      if (paragraph !== message.content[message.content.length - 1]) {
        result += '\n';
      }
    });

    return result;
  };

  const handleMessageChange = (e) => {
    const newValue = e.target.value;
    const lastChar = newValue.slice(-1);
    setMessage(newValue);

    // Only process shortcuts if the last character typed was a space
    if (lastChar === ' ') {
      // Check if the text before the space matches any shortcut
      const textBeforeSpace = newValue.slice(0, -1);
      const matchedShortcut = shortcuts.find((item) =>
        textBeforeSpace.endsWith(item.shortcut)
      );

      if (matchedShortcut) {
        const processedMessage = processMessage(matchedShortcut.message);
        const newMessage =
          newValue.slice(0, -matchedShortcut.shortcut.length - 1) +
          processedMessage +
          ' ';
        setMessage(newMessage);

        // Set cursor position to end of processed text
        setTimeout(() => {
          e.target.selectionStart = newMessage.length;
          e.target.selectionEnd = newMessage.length;
        }, 0);
      }
    }
  };

  const handleKeyDown = async (e) => {
    // Add CTRL+M handler for textarea, but don't prevent default
    if ((e.ctrlKey || e.metaKey) && e.key === 'm') {
      setIsCannedMessagePopoverOpen(true);
      return;
    }

    // If ctrl + enter is pressed, send message and remove focus from textarea
    if ((e.ctrlKey || e.metaKey) && e.key === 'Enter') {
      try {
        toast.loading('Loading...', {
          id: 'sending-message',
        });

        trackQuickCloseContactFeature();

        const response = await handleMessage(e);
        textareaRef?.current.blur();

        const newStatus = status === 'open' ? 'close' : 'open';

        // If status is open, update status to close
        const updateStatusResponse = await updateStatus();

        if (response?.status === 'success' && updateStatusResponse) {
          toast.dismiss('sending-message');
          toast.success('Message sent and status updated');
          if (selectedConversationStatus.value !== 'all') {
            switch (selectedConversationStatus.value) {
              // If the contact status is changed to the selectedConversationStatus
              case newStatus:
                console.log('insert conversation triggered', id);
                // addConversationToTop(contactData);
                increaseConversationCount();
                break;

              // If the contact status is changed to the opposite of selectedConversationStatus
              default:
                console.log('remove conversation triggered', id);
                removeConversation(id);
                decreaseConversationCount();
                break;
            }
          }

          // Find id index in conversations array
          const index = conversations.findIndex(
            (conversation) => conversation._id === id
          );

          // If index is found, navigate to next conversation
          // If index (-1) is not found, do not navigate
          // if index is last element, do not navigate

          if (index !== -1 && index !== conversations.length - 1) {
            navigate(`/inbox/${conversations[index + 1]._id}`, {
              replace: true,
            });
          } else {
            navigate(`/inbox`, {
              replace: true,
            });
          }
        }
      } catch (error) {
        toast.dismiss('sending-message');
        toast.error('Unable to send message');
      }
    } else if (e.key === 'Enter') {
      // If Shift key is also pressed, insert a newline character
      if (e.shiftKey) {
        // dont do anything
      } else {
        // Otherwise, prevent the default behavior (submitting the form) and perform your button click action
        e.preventDefault();
        handleMessage(e);
      }
    }

    // If esc is pressed, remove focus from textarea
    if (e.key === 'Escape') {
      textareaRef?.current.blur();
    }
  };

  const handleKeyPress = useCallback(() => {
    textareaRef?.current.focus();
    trackMessageInputClicked('keyboard');
  }, []);

  const handleOpenCannedMessages = useCallback((e) => {
    // Prevent default only for the global hotkey to avoid double-triggering
    if (e) e.preventDefault();
    setIsCannedMessagePopoverOpen(true);
  }, []);

  const hotkeys = useMemo(
    () => [
      {
        combo: 'R',
        global: true,
        group: 'Contact Chat',
        // disabled: !contactSidebarShow,
        label: 'Reply',
        onKeyUp: handleKeyPress,
      },
      {
        combo: 'alt+s',
        global: true, // Changed back to true for global accessibility
        group: 'Contact Chat',
        label: 'Open Saved Replies',
        onKeyDown: handleOpenCannedMessages, // Changed to onKeyDown and will handle preventDefault
      },
    ],
    [handleKeyPress, handleOpenCannedMessages]
  );
  const hotkey = useHotkeys(hotkeys);

  return (
    <>
      <div className='m-1 rounded-md'>
        <div className='m-1 mb-2 flex flex-col'>
          <div className='relative w-full'>
            {selectedPrivateReplyMessageId && (
              <div className='flex w-full flex-row items-center justify-between rounded-t-lg border-[2px] bg-background px-3 py-2 text-sm'>
                <div className='overflow-hidden text-ellipsis whitespace-nowrap'>
                  <span className='font-extralight text-primary'>
                    Private reply to Comment{' '}
                  </span>
                  <span className='font-medium'>
                    {selectedPrivateReplyMessage}
                  </span>
                </div>
                <XIcon
                  className='ml-2 h-5 w-5 flex-shrink-0 cursor-pointer'
                  onClick={() => resetSelectedPrivateReplyMessage()}
                />
              </div>
            )}
            <Textarea
              ref={textareaRef}
              rows='1'
              className={cn(
                'custom-scrollbar relative max-h-40 min-h-14 resize-none overflow-y-auto rounded-none border-0 bg-muted focus-visible:ring-0 focus-visible:ring-offset-0',
                isAttachmentSelected ? 'hidden' : 'flex',
                selectedPrivateReplyMessageId ? '' : 'rounded-t-lg'
              )}
              onInput={(e) => {
                const target = e.target;
                target.style.height = 'auto';
                target.style.height = `${target.scrollHeight}px`;
              }}
              value={message}
              onChange={handleMessageChange}
              onClick={() => trackMessageInputClicked('mouse')}
              placeholder={
                'To type a message, press R. To add a new line, press Shift + Enter.'
              }
              onKeyDown={handleKeyDown}
            />
          </div>
          <div
            className={cn(
              'flex items-center justify-between bg-muted px-2 ',
              messageType === 'text' ? 'rounded-b-lg pb-2' : 'rounded-lg py-1'
            )}
          >
            <div className='flex w-full items-center justify-start gap-2'>
              {messageType === 'text' && (
                <CannedMessagesPopover
                  shortcuts={shortcuts}
                  onSelect={(selectedText) => {
                    setMessage((prev) => prev + selectedText);
                    setTimeout(() => {
                      textareaRef.current?.focus();
                      const len = textareaRef.current?.value.length;
                      textareaRef.current?.setSelectionRange(len, len);
                    }, 0);
                  }}
                  processMessage={processMessage}
                  open={isCannedMessagePopoverOpen}
                  setOpen={setIsCannedMessagePopoverOpen}
                  textareaRef={textareaRef}
                />
              )}
              {channel === 'instagram' && (
                <FileAttachment
                  setIsAttachmentSelected={setIsAttachmentSelected}
                  messageType={messageType}
                  setMessageType={setMessageType}
                  addMessage={addMessage}
                />
              )}
            </div>
            {messageType === 'text' && (
              <Button
                size='xs'
                variant='ghost'
                className='text-primary hover:text-primary/90'
                onClick={(e) => handleMessage(e)}
              >
                <SendHorizonalIcon />
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
