import { useEffect } from 'react';
import Mention from '@tiptap/extension-mention';
import Placeholder from '@tiptap/extension-placeholder';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';

import suggestion from './suggestion';

const MentionsEditor = ({ value, onChange }) => {
  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        hardBreak: true,
        // Disable all nodes except paragraph
        blockquote: false,
        bulletList: false,
        codeBlock: false,
        heading: false,
        horizontalRule: false,
        listItem: false,
        orderedList: false,
        // Disable all marks
        bold: false,
        code: false,
        italic: false,
        strike: false,
      }),
      Placeholder.configure({
        placeholder: 'Sample Message',
        emptyEditorClass:
          'cursor-text before:content-[attr(data-placeholder)] before:text-muted-foreground before:absolute before:top-2 before:left-2 before-pointer-events-none',
      }),
      Mention.configure({
        HTMLAttributes: {
          class:
            'bg-muted rounded-[0.4rem] px-[0.3rem] py-[0.1rem] box-decoration-clone after:content-["\x80"]',
        },
        suggestion,
      }),
    ],
    content: value,
    editorProps: {
      attributes: {
        class:
          'min-h-[200px] border rounded-md p-2 rounded-[inherit] ring-offset-none focus-visible:outline-none focus-visible:ring-none focus-visible:ring-offset-0 focus-visible:ring-none',
      },
    },
    onUpdate: ({ editor }) => {
      onChange(editor.getJSON());
    },
  });

  // Add this useEffect to update editor content when value changes
  useEffect(() => {
    if (editor && value) {
      // Only update if the content is different
      if (JSON.stringify(editor.getJSON()) !== JSON.stringify(value)) {
        editor.commands.setContent(value);
      }
    }
  }, [editor, value]);

  return <EditorContent className='pb-5 text-sm' editor={editor} />;
};

export default MentionsEditor;
