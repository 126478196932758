import { useCallback, useEffect, useMemo, useState } from 'react';
import useContactStore from '@/stores/ContactStore';
import useInboxStore from '@/stores/InboxStore';
import useTeamMemberStore from '@/stores/TeamMemberStore';
import useUserStore from '@/stores/UserStore';
import { useHotkeys } from '@blueprintjs/core';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';
import { useShallow } from 'zustand/react/shallow';

import {
  trackContactOwnerButtonClicked,
  trackOwnerChanged,
} from '@/lib/analytics-event';
import { setNewAgent } from '@/hooks/agent';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import TooltipComponent from '@/components/common/tooltip/tooltip';

const ContactOwner = () => {
  const { owners, contactSidebarShow } = useInboxStore(
    useShallow((state) => ({
      owners: state.owners,
      contactSidebarShow: state.contactSidebarShow,
    }))
  );
  const clientId = useUserStore((state) => state.clientId);

  const { owner, setContactOwner } = useContactStore(
    useShallow((state) => ({
      owner: state.owner,
      setContactOwner: state.setContactOwner,
    }))
  );

  // Conversation List filter by contact id
  const { id: contactId } = useParams();

  const [ownerDropdownOpen, setOwnerDropdownOpen] = useState(false);

  const handleRefresh = useCallback(() => {
    trackContactOwnerButtonClicked('keyboard');
    setOwnerDropdownOpen(true);
  }, []);

  const hotkeys = useMemo(
    () => [
      {
        combo: 'O',
        global: true,
        group: 'Contact Details',
        disabled: !contactSidebarShow,
        label: 'Assign owner',
        onKeyDown: handleRefresh,
      },
    ],
    [handleRefresh, contactSidebarShow]
  );
  const { handleKeyDown, handleKeyUp } = useHotkeys(hotkeys);

  const handleAssignOwner = async (ownerId) => {
    try {
      const response = await setNewAgent(ownerId, contactId, clientId);
      trackOwnerChanged(owner, ownerId); //owner -> old owner, ownerId -> new owner
      setContactOwner(ownerId);

      toast.success('Owner assigned successfully');
    } catch (error) {
      console.error(error);
      // Handle the error appropriately here
      toast.error('Failed to assign owner');
    }
  };

  const filteredOwners = owners.filter((owner) => owner.role !== 'deleted');

  return (
    <div
      className='space-y-2 border-b border-borderColor px-4'
      onKeyUp={handleKeyUp}
      onKeyDown={handleKeyDown}
    >
      <div className=' text-sm font-medium'>Contact Owner</div>

      <div className='pb-5'>
        <Select
          value={owner}
          open={ownerDropdownOpen}
          onOpenChange={setOwnerDropdownOpen}
          onValueChange={handleAssignOwner}
          className='text-left'
        >
          <TooltipComponent
            content={
              <div className='flex space-x-2'>
                <div>Assign owner</div>

                <kbd className='pointer-events-none  hidden h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium opacity-100 sm:flex'>
                  O
                </kbd>
              </div>
            }
          >
            <SelectTrigger>
              <SelectValue placeholder='Select a Contact '></SelectValue>
            </SelectTrigger>
          </TooltipComponent>
          <SelectContent onCloseAutoFocus={(e) => e.preventDefault()}>
            <SelectGroup>
              {filteredOwners.map((owner) => {
                return (
                  <SelectItem key={owner._id} value={owner._id}>
                    <div className='flex cursor-pointer items-center space-x-2 text-sm'>
                      <Avatar className='h-6 w-6'>
                        <AvatarImage
                          src={owner.profile_picture_url}
                          className='text-xs '
                        />
                        <AvatarFallback className='text-xs '>
                          {owner.first_name.charAt(0)}
                          {owner.last_name.charAt(0)}
                        </AvatarFallback>
                      </Avatar>
                      <div>
                        {owner.first_name} {owner.last_name}
                      </div>
                    </div>
                  </SelectItem>
                );
              })}
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>
    </div>
  );
};

export default ContactOwner;
