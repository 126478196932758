import {
  agentCreate,
  agentLeadAllocation,
  agentList,
  assignAgent,
  deleteAgent,
  goals,
} from '@/constants/endpoints';

import { APIClient } from '@/lib/ApiClient';

const apiClient = new APIClient();

export function fetchAgents() {
  return apiClient.get(agentList, null, true);
}

export function createAgent(agentData) {
  return apiClient.create(agentCreate, agentData, true);
}

export function setNewAgent(agentId, contactId, clientId) {
  return apiClient.create(
    assignAgent,
    { user_id: agentId, contact_id: contactId },
    true,
    clientId
  );
}

export function fetchGoals() {
  return apiClient.get(goals, null, true);
}

export function setGoals(goalData) {
  return apiClient.create(goals, goalData, true);
}

export function setLeadAllocation(leadAllocationData) {
  return apiClient.create(
    agentLeadAllocation,
    { allocations: leadAllocationData },
    true
  );
}

export function deleteAgentAndRedistribute(agentId, redistributionData) {
  const payload = redistributionData.length
    ? { existing_contact_distribution: redistributionData }
    : {};
  return apiClient.delete(deleteAgent(agentId), payload, true);
}
