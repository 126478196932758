import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../ui/dialog';
import { ScrollArea } from '../ui/scroll-area';
import KeyboardShortcut from './keyboard/keyboard';

const CustomHotkeysDialog = (state, contextActions) => {
  const { hotkeys, isDialogOpen } = state;
  const { handleDialogClose } = contextActions;

  const isMac = navigator.platform.toLowerCase().includes('mac');

  const groupedHotkeys = hotkeys.reduce((acc, hotkey) => {
    const { group = 'Global', combo, ...rest } = hotkey;
    const comboArray = combo.split('+').map((key) => {
      const trimmedKey = key.trim();
      const capitalizedKey =
        trimmedKey.charAt(0).toUpperCase() + trimmedKey.slice(1);
      return isMac && capitalizedKey === 'Alt' ? 'Option' : capitalizedKey;
    });
    acc[group] = acc[group] || [];
    acc[group].push({ ...rest, combo: comboArray });
    return acc;
  }, {});

  return (
    <>
      {Object.keys(groupedHotkeys).length > 0 && (
        <Dialog
          open={isDialogOpen}
          onOpenChange={handleDialogClose}
          modal={true}
        >
          <DialogContent className='flex max-h-[80vh] flex-col'>
            <DialogHeader>
              <DialogTitle>Hotkeys List</DialogTitle>
            </DialogHeader>
            <ScrollArea className='h-[90vh]'>
              <div className='-mx-6 flex-1 overflow-y-scroll px-6 py-2'>
                {Object.entries(groupedHotkeys).map(([group, keys]) => (
                  <div key={group}>
                    <h3 className='mt-4 font-semibold'>{group}</h3>
                    <ul className='list-none p-0'>
                      {keys.map((key, index) => (
                        <li
                          key={index}
                          className='flex items-center justify-between py-1'
                        >
                          <span>{key.label}</span>
                          <KeyboardShortcut keys={key.combo} />
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </ScrollArea>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default CustomHotkeysDialog;
