/* eslint-disable max-lines */
'use client';

import React, { useEffect, useRef, useState } from 'react';
import {
  CalendarIcon,
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@radix-ui/react-icons';

import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import { Label } from '@/components/ui/label';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectSeparator,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Separator } from '@/components/ui/separator';
import { Switch } from '@/components/ui/switch';

import { DateInput } from '../date-input/data-input';

const formatDate = (date, locale = 'en-us') => {
  return date.toLocaleDateString(locale, {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });
};

const getDateAdjustedForTimezone = (dateInput) => {
  if (typeof dateInput === 'string') {
    const parts = dateInput.split('-').map((part) => parseInt(part, 10));
    const date = new Date(parts[0], parts[1] - 1, parts[2]);
    return date;
  } else {
    return dateInput;
  }
};

const PRESETS = {
  // popular: [
  //   { name: 'all-time', label: 'All Time' },
  //   { name: 'week-to-date', label: 'Week to Date' },
  //   { name: 'last-week', label: 'Last Week' },
  //   { name: 'month-to-date', label: 'Month to Date' },
  //   { name: 'last-month', label: 'Last Month' },
  // ],
  current: [
    { name: 'today', label: 'Today' },
    { name: 'week-to-date', label: 'Week to Date' },
    { name: 'month-to-date', label: 'Month to Date' },
    { name: 'quarter-to-date', label: 'Quarter to Date' },
    { name: 'year-to-date', label: 'Year to Date' },
  ],
  previous: [
    { name: 'yesterday', label: 'Yesterday' },
    { name: 'last-week', label: 'Last Week' },
    { name: 'last-month', label: 'Last Month' },
    { name: 'last-quarter', label: 'Last Quarter' },
    { name: 'last-year', label: 'Last Year' },
    { name: 'all-time', label: 'All Time' },
  ],
  custom: [{ name: 'custom', label: 'Custom Date' }],
};

export const DateRangePicker = ({
  defaultPreset = 'custom',
  initialDateFrom = new Date(new Date().setHours(0, 0, 0, 0)),
  initialDateTo,
  initialCompareFrom,
  initialCompareTo,
  onUpdate,
  align = 'end',
  locale = 'en-US',
  showCompare = true,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const [range, setRange] = useState({
    from: getDateAdjustedForTimezone(initialDateFrom),
    to: initialDateTo
      ? getDateAdjustedForTimezone(initialDateTo)
      : getDateAdjustedForTimezone(initialDateFrom),
  });
  const [rangeCompare, setRangeCompare] = useState(
    initialCompareFrom
      ? {
          from: new Date(new Date(initialCompareFrom).setHours(0, 0, 0, 0)),
          to: initialCompareTo
            ? new Date(new Date(initialCompareTo).setHours(0, 0, 0, 0))
            : new Date(new Date(initialCompareFrom).setHours(0, 0, 0, 0)),
        }
      : undefined
  );

  const openedRangeRef = useRef();
  const openedRangeCompareRef = useRef();

  const [selectedPreset, setSelectedPreset] = useState(defaultPreset);

  const [isSmallScreen, setIsSmallScreen] = useState(
    true
    // typeof window !== 'undefined' ? window.innerWidth < 960 : false
  );

  useEffect(() => {
    const handleResize = () => {
      //   setIsSmallScreen(window.innerWidth < 960);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const getPresetRange = (presetName) => {
    const preset = Object.values(PRESETS)
      .flat()
      .find(({ name }) => name === presetName);
    if (!preset) throw new Error(`Unknown date range preset: ${presetName}`);
    const from = new Date();
    const to = new Date();
    const first = from.getDate() - from.getDay();
    const currentMonth = from.getMonth();
    const startMonth = currentMonth - (currentMonth % 3);

    const currentQuarterMonth = from.getMonth();
    const quarterStartMonth = currentQuarterMonth - (currentQuarterMonth % 3);

    const lastQuarterMonth = from.getMonth() - 3;
    const lastQuarterStartMonth = lastQuarterMonth - (lastQuarterMonth % 3);

    switch (preset.name) {
      case 'today':
        from.setHours(0, 0, 0, 0);
        to.setHours(23, 59, 59, 999);
        break;
      case 'yesterday':
        from.setDate(from.getDate() - 1);
        from.setHours(0, 0, 0, 0);
        to.setDate(to.getDate() - 1);
        to.setHours(23, 59, 59, 999);
        break;
      case 'last7':
        from.setDate(from.getDate() - 7);
        from.setHours(0, 0, 0, 0);
        to.setHours(23, 59, 59, 999);
        break;
      case 'last14':
        from.setDate(from.getDate() - 14);
        from.setHours(0, 0, 0, 0);
        to.setHours(23, 59, 59, 999);
        break;
      case 'last30':
        from.setDate(from.getDate() - 30);
        from.setHours(0, 0, 0, 0);
        to.setHours(23, 59, 59, 999);
        break;
      case 'week-to-date':
        from.setDate(first);
        from.setHours(0, 0, 0, 0);
        to.setHours(23, 59, 59, 999);
        break;
      case 'last-week':
        from.setDate(from.getDate() - 7 - from.getDay());
        to.setDate(to.getDate() - to.getDay() - 1);
        from.setHours(0, 0, 0, 0);
        to.setHours(23, 59, 59, 999);
        break;
      case 'month-to-date':
        from.setDate(1);
        from.setHours(0, 0, 0, 0);
        to.setHours(23, 59, 59, 999);
        break;
      case 'last-month':
        from.setMonth(from.getMonth() - 1);
        from.setDate(1);
        from.setHours(0, 0, 0, 0);
        to.setMonth(to.getMonth() - 1);
        to.setDate(new Date(to.getFullYear(), to.getMonth() + 1, 0).getDate());
        to.setHours(23, 59, 59, 999);
        break;
      case 'quarter-to-date':
        from.setMonth(startMonth);
        from.setDate(1);
        from.setHours(0, 0, 0, 0);
        to.setHours(23, 59, 59, 999);
        break;
      case 'last-quarter':
        from.setMonth(lastQuarterStartMonth);
        from.setDate(1);
        from.setHours(0, 0, 0, 0);
        to.setMonth(lastQuarterStartMonth + 2);
        to.setDate(new Date(to.getFullYear(), to.getMonth() + 1, 0).getDate());
        to.setHours(23, 59, 59, 999);
        break;
      case 'year-to-date':
        from.setMonth(0);
        from.setDate(1);
        from.setHours(0, 0, 0, 0);
        to.setHours(23, 59, 59, 999);
        break;
      case 'last-year':
        from.setFullYear(from.getFullYear() - 1);
        from.setMonth(0);
        from.setDate(1);
        from.setHours(0, 0, 0, 0);
        to.setFullYear(to.getFullYear() - 1);
        to.setMonth(11);
        to.setDate(31);
        to.setHours(23, 59, 59, 999);
        break;
      case 'all-time':
        from.setFullYear(2000);
        from.setMonth(0);
        from.setDate(1);
        from.setHours(0, 0, 0, 0);
        to.setFullYear(new Date().getFullYear());
        to.setMonth(new Date().getMonth());
        to.setDate(new Date().getDate());
        to.setHours(23, 59, 59, 999);
        break;
    }

    return { from, to };
  };

  const setPreset = (preset) => {
    //Selected Preset
    setSelectedPreset(preset);
    const range = getPresetRange(preset);
    setRange(range);
    if (rangeCompare) {
      const rangeCompare = {
        from: new Date(
          range.from.getFullYear() - 1,
          range.from.getMonth(),
          range.from.getDate()
        ),
        to: range.to
          ? new Date(
              range.to.getFullYear() - 1,
              range.to.getMonth(),
              range.to.getDate()
            )
          : undefined,
      };
      setRangeCompare(rangeCompare);
    }
  };

  const checkPreset = () => {
    // Check all preset categories
    for (const category of Object.values(PRESETS)) {
      for (const preset of category) {
        const presetRange = getPresetRange(preset.name);

        const normalizedRangeFrom = new Date(range.from);
        normalizedRangeFrom.setHours(0, 0, 0, 0);
        const normalizedPresetFrom = new Date(presetRange.from);
        normalizedPresetFrom.setHours(0, 0, 0, 0);

        const normalizedRangeTo = new Date(range.to ?? 0);
        normalizedRangeTo.setHours(0, 0, 0, 0);
        const normalizedPresetTo = new Date(presetRange.to ?? 0);
        normalizedPresetTo.setHours(0, 0, 0, 0);

        if (
          normalizedRangeFrom.getTime() === normalizedPresetFrom.getTime() &&
          normalizedRangeTo.getTime() === normalizedPresetTo.getTime()
        ) {
          setSelectedPreset(preset.name);
          return;
        }
      }
    }

    setSelectedPreset('custom');
  };

  const resetValues = () => {
    setRange({
      from:
        typeof initialDateFrom === 'string'
          ? getDateAdjustedForTimezone(initialDateFrom)
          : initialDateFrom,
      to: initialDateTo
        ? typeof initialDateTo === 'string'
          ? getDateAdjustedForTimezone(initialDateTo)
          : initialDateTo
        : typeof initialDateFrom === 'string'
          ? getDateAdjustedForTimezone(initialDateFrom)
          : initialDateFrom,
    });
    setRangeCompare(
      initialCompareFrom
        ? {
            from:
              typeof initialCompareFrom === 'string'
                ? getDateAdjustedForTimezone(initialCompareFrom)
                : initialCompareFrom,
            to: initialCompareTo
              ? typeof initialCompareTo === 'string'
                ? getDateAdjustedForTimezone(initialCompareTo)
                : initialCompareTo
              : typeof initialCompareFrom === 'string'
                ? getDateAdjustedForTimezone(initialCompareFrom)
                : initialCompareFrom,
          }
        : undefined
    );
  };

  // useEffect(() => {
  //   checkPreset();
  // }, [range]);

  const PresetButton = ({ preset, label, isSelected }) => (
    <Button
      className={cn(isSelected && 'pointer-events-none')}
      variant='ghost'
      onClick={() => {
        setPreset(preset);
      }}
    >
      <>
        <span className={cn('pr-2 opacity-0', isSelected && 'opacity-70')}>
          <CheckIcon width={18} height={18} />
        </span>
        {label}
      </>
    </Button>
  );

  const areRangesEqual = (a, b) => {
    if (!a || !b) return a === b;
    return (
      a.from.getTime() === b.from.getTime() &&
      (!a.to || !b.to || a.to.getTime() === b.to.getTime())
    );
  };

  useEffect(() => {
    if (isOpen) {
      openedRangeRef.current = range;
      openedRangeCompareRef.current = rangeCompare;
    }
  }, [isOpen]);

  return (
    <Popover
      modal={true}
      open={isOpen}
      onOpenChange={(open) => {
        if (!open) {
          resetValues();
        }
        setIsOpen(open);
      }}
    >
      <PopoverTrigger asChild>
        <Button size={'lg'} variant='outline'>
          <div className='mr-2 flex items-center'>
            <CalendarIcon className='mr-2' width={15} height={15} />
            <span>Date Range:</span>
          </div>
          <div className='text-right'>
            <div className='py-1'>
              {selectedPreset === 'custom' ? (
                <div>{`${formatDate(range.from, locale)}${
                  range.to != null ? ' - ' + formatDate(range.to, locale) : ''
                }`}</div>
              ) : (
                <div>
                  {
                    Object.values(PRESETS)
                      .flat()
                      .find((preset) => preset.name === selectedPreset)?.label
                  }
                </div>
              )}
            </div>
            {rangeCompare != null && (
              <div className='-mt-1 text-xs opacity-60'>
                <>
                  vs. {formatDate(rangeCompare.from, locale)}
                  {rangeCompare.to != null
                    ? ` - ${formatDate(rangeCompare.to, locale)}`
                    : ''}
                </>
              </div>
            )}
          </div>
          <div className='-mr-2 scale-125 pl-1 opacity-60'>
            {isOpen ? (
              <ChevronUpIcon width={24} />
            ) : (
              <ChevronDownIcon width={24} />
            )}
          </div>
        </Button>
      </PopoverTrigger>
      <PopoverContent align={align} className='w-auto'>
        <div className='flex py-2'>
          <div className='flex'>
            <div className='flex flex-col'>
              <div className='flex flex-col items-center justify-end gap-2 px-3 pb-4 lg:flex-row lg:items-start lg:pb-0'>
                {showCompare && (
                  <div className='flex items-center space-x-2 py-1 pr-4'>
                    <Switch
                      defaultChecked={Boolean(rangeCompare)}
                      onCheckedChange={(checked) => {
                        if (checked) {
                          if (!range.to) {
                            setRange({
                              from: range.from,
                              to: range.from,
                            });
                          }
                          setRangeCompare({
                            from: new Date(
                              range.from.getFullYear(),
                              range.from.getMonth(),
                              range.from.getDate() - 365
                            ),
                            to: range.to
                              ? new Date(
                                  range.to.getFullYear() - 1,
                                  range.to.getMonth(),
                                  range.to.getDate()
                                )
                              : new Date(
                                  range.from.getFullYear() - 1,
                                  range.from.getMonth(),
                                  range.from.getDate()
                                ),
                          });
                        } else {
                          setRangeCompare(undefined);
                        }
                      }}
                      id='compare-mode'
                    />
                    <Label htmlFor='compare-mode'>Compare</Label>
                  </div>
                )}
                <div className='flex flex-col gap-2'>
                  <div className='flex gap-2'>
                    <DateInput
                      value={range.from}
                      onChange={(date) => {
                        const toDate =
                          range.to == null || date > range.to ? date : range.to;
                        setRange((prevRange) => ({
                          ...prevRange,
                          from: date,
                          to: toDate,
                        }));
                      }}
                    />
                    <div className='py-1'>-</div>
                    <DateInput
                      value={range.to}
                      onChange={(date) => {
                        const fromDate = date < range.from ? date : range.from;
                        setRange((prevRange) => ({
                          ...prevRange,
                          from: fromDate,
                          to: date,
                        }));
                      }}
                    />
                  </div>
                  {rangeCompare != null && (
                    <div className='flex gap-2'>
                      <DateInput
                        value={rangeCompare?.from}
                        onChange={(date) => {
                          if (rangeCompare) {
                            const compareToDate =
                              rangeCompare.to == null || date > rangeCompare.to
                                ? date
                                : rangeCompare.to;
                            setRangeCompare((prevRangeCompare) => ({
                              ...prevRangeCompare,
                              from: date,
                              to: compareToDate,
                            }));
                          } else {
                            setRangeCompare({
                              from: date,
                              to: new Date(),
                            });
                          }
                        }}
                      />
                      <div className='py-1'>-</div>
                      <DateInput
                        value={rangeCompare?.to}
                        onChange={(date) => {
                          if (rangeCompare && rangeCompare.from) {
                            const compareFromDate =
                              date < rangeCompare.from
                                ? date
                                : rangeCompare.from;
                            setRangeCompare({
                              ...rangeCompare,
                              from: compareFromDate,
                              to: date,
                            });
                          }
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
              {isSmallScreen && (
                <Select
                  defaultValue={selectedPreset}
                  onValueChange={(value) => {
                    setPreset(value);
                  }}
                  value={selectedPreset}
                >
                  <SelectTrigger className='mx-auto mb-2 mt-1 w-[180px]'>
                    <SelectValue placeholder={'Select...'} />
                  </SelectTrigger>
                  <SelectContent>
                    {/* <SelectGroup>
                      <SelectLabel className='pl-4'>Popular</SelectLabel>
                      <SelectSeparator />
                      {PRESETS.popular.map((preset) => (
                        <SelectItem key={preset.name} value={preset.name}>
                          {preset.label}
                        </SelectItem>
                      ))}
                    </SelectGroup>
                    <SelectSeparator /> */}
                    <SelectGroup>
                      <SelectLabel className='pl-4'>Current</SelectLabel>
                      <SelectSeparator />
                      {PRESETS.current.map((preset) => (
                        <SelectItem key={preset.name} value={preset.name}>
                          {preset.label}
                        </SelectItem>
                      ))}
                    </SelectGroup>
                    <SelectSeparator />
                    <SelectGroup>
                      <SelectLabel className='pl-4'>Previous</SelectLabel>
                      <SelectSeparator />
                      {PRESETS.previous.map((preset) => (
                        <SelectItem key={preset.name} value={preset.name}>
                          {preset.label}
                        </SelectItem>
                      ))}
                    </SelectGroup>
                    <SelectSeparator />
                    <SelectGroup>
                      <SelectLabel className='pl-4'>Custom</SelectLabel>
                      <SelectSeparator />
                      {PRESETS.custom.map((preset) => (
                        <SelectItem key={preset.name} value={preset.name}>
                          {preset.label}
                        </SelectItem>
                      ))}
                    </SelectGroup>
                    <SelectSeparator />
                  </SelectContent>
                </Select>
              )}
              <div>
                <Calendar
                  mode='range'
                  onSelect={(value) => {
                    if (value?.from != null) {
                      setRange({ from: value.from, to: value?.to });
                      setSelectedPreset('custom');
                    }
                  }}
                  selected={range}
                  numberOfMonths={isSmallScreen ? 1 : 2}
                  defaultMonth={
                    new Date(
                      new Date().setMonth(
                        new Date().getMonth() - (isSmallScreen ? 0 : 1)
                      )
                    )
                  }
                />
              </div>
            </div>
          </div>
          {/* {Below Condition Code not use} */}
          {!isSmallScreen && (
            <div className='flex flex-col items-end gap-1 pb-6 pl-6 pr-2'>
              <div className='flex w-full flex-col items-end gap-1 pb-6 pl-6 pr-2'>
                {PRESETS.map((preset) => (
                  <PresetButton
                    key={preset.name}
                    preset={preset.name}
                    label={preset.label}
                    isSelected={selectedPreset === preset.name}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
        <div className='flex justify-end gap-2 py-2 pr-4'>
          <Button
            onClick={() => {
              setIsOpen(false);
              resetValues();
            }}
            variant='ghost'
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              setIsOpen(false);
              if (
                !areRangesEqual(range, openedRangeRef.current) ||
                !areRangesEqual(rangeCompare, openedRangeCompareRef.current)
              ) {
                onUpdate?.({ range, rangeCompare, selectedPreset });
              }
            }}
          >
            Update
          </Button>
        </div>
      </PopoverContent>
    </Popover>
  );
};
