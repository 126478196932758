import { formatNumberWithSuffix } from '@/lib/utils';
import { ScrollArea } from '@/components/ui/scroll-area';
import { DataTable } from '@/components/common/table/data-table';
import { DataTableColumnHeader } from '@/components/common/table/data-table-column-header';

const SalesLeaderBoard = ({ SalesLeaderBoardData }) => {
  const columns = [
    {
      accessorKey: 'name',
      id: 'name',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title='Agent' />
      ),
      cell: (record) => (
        <div>
          <div className='mb-1 text-sm font-medium'>{record.getValue()}</div>
        </div>
      ),
    },
    {
      accessorKey: 'current_value',
      id: 'current_value',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title='Value' />
      ),
      cell: (record) => (
        <div>
          <div className='mb-1 text-sm font-medium'>
            ${formatNumberWithSuffix(record.getValue())}
          </div>
        </div>
      ),
    },
    {
      accessorKey: 'current_goal',
      id: 'current_goal',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title='Goal' />
      ),
      cell: (record) => {
        const value = record.row.original.current_value;
        const goal = record.row.original.current_goal;
        const remainingPercentage = (((goal - value) / goal) * 100).toFixed(0);
        const textColor = remainingPercentage > 0 ? '#B91C1C' : '#166534';
        const bgColor = remainingPercentage > 0 ? '#FEE2E2' : '#DCFCE7';

        return goal ? (
          <div>
            <div
              style={{
                display: 'flex',
                width: 'fit-content',
                alignItems: 'center',
                borderRadius: '0.375rem',
                backgroundColor: bgColor,
                padding: '0.125rem 0.5rem',
                color: textColor,
                position: 'relative',
              }}
              title={`Goal: $${goal}`}
            >
              <div style={{ fontSize: '0.875rem', fontWeight: '500' }}>
                {remainingPercentage > 0 ? '↓' : '↑'}{' '}
                {Math.abs(remainingPercentage)}%
              </div>
            </div>
          </div>
        ) : null;
      },
    },
  ];

  return (
    <div className='light:bg-white flex h-full w-full flex-col gap-2 overflow-auto rounded-lg border border-gray-200 dark:border-gray-700'>
      <div className='text-center'>
        <h3 className='my-2 text-lg font-normal'>Sales Leaderboard</h3>
      </div>
      <ScrollArea className='h-full w-full overflow-auto'>
        <DataTable
          columns={columns}
          data={SalesLeaderBoardData}
          hideTheseColumnsInSm={{}}
          pagination={false}
          isBorderEnabled={false}
        />
      </ScrollArea>
    </div>
  );
};

export default SalesLeaderBoard;
