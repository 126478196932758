import { Link, Outlet, useLocation } from 'react-router-dom';

import { cn } from '@/lib/utils';
import { buttonVariants } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';

const SettingLayout = () => {
  const navConfig = {
    Main: [
      {
        title: 'Team Members',
        href: '/settings/team-members',
      },
      {
        title: 'Plans',
        href: '/settings/plans',
      },
      {
        title: 'Saved Replies',
        href: '/settings/saved-replies',
      },
    ],
    Channels: [
      {
        title: 'Instagram & Fb',
        href: '/settings/instagram',
      },
    ],
  };

  return (
    <>
      <div className='hidden h-svh  space-y-6 md:block'>
        <div className='flex h-full flex-col  space-y-8 lg:flex-row lg:space-x-4 lg:space-y-0'>
          <aside className='space-y-2 px-6 py-4 lg:min-w-[380px] lg:max-w-[380px]'>
            <div className='space-y-0.5'>
              <h2 className='text-xl font-bold tracking-tight'>Settings</h2>
              <p className='text-muted-foreground'>
                Manage your account settings.
              </p>
            </div>
            <SidebarNav navConfig={navConfig} />
          </aside>
          <Separator className='' orientation='vertical' />
          <div className='flex-1 p-5'>
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingLayout;

function SidebarNav({ className, navConfig, ...props }) {
  const location = useLocation();

  const { pathname } = location;

  return (
    <>
      <nav
        className={cn(
          'flex space-x-2 lg:flex-col lg:space-x-0 lg:space-y-1',
          className
        )}
        {...props}
      >
        <ul role='list' className=' space-y-1'>
          {Object.keys(navConfig).map((section) => (
            <li key={section}>
              <div
                key={section}
                className='my-5 text-xs font-semibold leading-6'
              >
                {section}
                <ul role='list' className=' mt-2 space-y-3'>
                  {navConfig[section].map((item) => (
                    <li key={item.href}>
                      <Link
                        key={item.href}
                        to={`${item.href}`}
                        className={cn(
                          buttonVariants({ variant: 'ghost' }),
                          pathname === item.href
                            ? 'bg-muted hover:bg-muted'
                            : 'hover:bg-muted',
                          'w-full justify-start'
                        )}
                      >
                        <span className='flex items-center'>
                          {item.icon && <item.icon className='mr-2' />}
                          <span className='truncate'>{item.title}</span>
                        </span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </li>
          ))}
        </ul>
      </nav>
    </>
  );
}
