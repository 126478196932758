import { useEffect, useState } from 'react';
import useTeamMemberStore from '@/stores/TeamMemberStore';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import * as z from 'zod';
import { useShallow } from 'zustand/react/shallow';

import { setGoals } from '@/hooks/agent';

import { ButtonLoading } from '../common/button/loading-button';
import { Button } from '../ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../ui/form';
import { Input } from '../ui/input';
import { ScrollArea } from '../ui/scroll-area';

const FormSchema = z.object({
  companyGoal: z.coerce
    .number()
    .min(1, 'Company goal must be a positive number'),
  agentGoals: z.array(
    z.object({
      agentId: z.string(),
      goal: z.coerce.number().min(1, 'Goal must be a positive number'),
    })
  ),
});

const GoalDialog = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const { companyGoal, updateCompanyGoal, agentGoals, updateAgentGoalAmount } =
    useTeamMemberStore(
      useShallow((state) => ({
        companyGoal: state.companyGoal,
        updateCompanyGoal: state.updateCompanyGoal,
        agentGoals: state.agentGoals,
        updateAgentGoalAmount: state.updateAgentGoalAmount,
      }))
    );

  const form = useForm({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      companyGoal: '',
      agentGoals: [],
    },
  });

  const handleClose = () => {
    setIsLoading(false);
    setOpen(false);
    form.reset();
  };

  useEffect(() => {
    // Set Company goal in the form if it exists
    form.setValue('companyGoal', companyGoal?.amount || 0);
    // Set agent goals in the form if they exist
    agentGoals.forEach((agent, index) => {
      form.setValue(`agentGoals[${index}].agentId`, agent._id);
      form.setValue(`agentGoals[${index}].goal`, agent.goal?.amount || 0);
    });
  }, [agentGoals, companyGoal, form]);

  const onSubmit = (values) => {
    setIsLoading(true);

    let companyGoal = {
      type: 'company',
      amount: values.companyGoal,
      date: new Date().toISOString().split('T')[0],
    };

    let agentGoals = values.agentGoals.map((agent) => ({
      type: 'agent',
      amount: agent.goal,
      user_id: agent.agentId,
      date: new Date().toISOString().split('T')[0],
    }));

    toast.promise(setGoals([companyGoal, ...agentGoals]), {
      loading: 'Saving goals...',
      success: (response) => {
        updateCompanyGoal(values.companyGoal);
        updateAgentGoalAmount(values.agentGoals);
        handleClose();

        return 'Goals updated successfully';
      },
      error: (error) => {
        setIsLoading(false);
        return 'Failed to save goals';
      },
    });
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant='outline'>Set Goals</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Set Goals</DialogTitle>
          <DialogDescription>
            Set your sales goals for the company and the respective agents for
            the <span className='font-bold text-black'>current month</span>
          </DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit, (errors) => {
              console.error('Form validation failed:', errors);
            })}
            className='space-y-6'
          >
            <FormField
              control={form.control}
              name='companyGoal'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Company Goal</FormLabel>
                  <FormControl>
                    <Input
                      className='w-2/4'
                      type='number'
                      placeholder='Enter company goal'
                      {...field}
                      onChange={(e) => field.onChange(e.target.value)}
                      value={field.value || ''}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {agentGoals && (
              <div className='space-y-4'>
                <h4 className='font-medium'>Agent Goals</h4>
                <ScrollArea type='always'>
                  <div className='max-h-[450px]'>
                    <div className='grid grid-cols-2 gap-x-4 gap-y-4'>
                      {agentGoals.map((agent, index) => (
                        <FormField
                          key={index}
                          control={form.control}
                          name={`agentGoals[${index}].goal`}
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>
                                {agent.first_name} {agent.last_name}&apos;s Goal
                              </FormLabel>
                              <FormControl>
                                <Input
                                  type='number'
                                  placeholder='Goal Amount'
                                  {...field}
                                  onChange={(e) => {
                                    field.onChange(e.target.value);
                                    form.setValue(
                                      `agentGoals[${index}].agentId`,
                                      agent._id
                                    );
                                  }}
                                  value={field.value || ''}
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      ))}
                    </div>
                  </div>
                </ScrollArea>
              </div>
            )}

            {/* <Button type='submit'>Save Goals</Button> */}
            <DialogFooter>
              <Button variant='secondary' onClick={() => handleClose()}>
                Cancel
              </Button>
              {isLoading ? (
                <ButtonLoading />
              ) : (
                <Button type='submit'>Save Goals</Button>
              )}
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default GoalDialog;
