import { TriangleDownIcon, TriangleUpIcon } from '@radix-ui/react-icons';

// TODO: Remove file after reports are implemented
const chartToolTip = () => {
  return (
    <div className='w-72 rounded-lg bg-white p-4 shadow-sm'>
      <div className='mb-3 flex flex-col gap-2'>
        <div className='text-sm font-medium text-gray-500'>Sales in $</div>

        <div className='rounded-md bg-gray-200 p-2'>
          <div className='text-sm text-gray-400'>Period</div>
          <div className='text-xl font-bold text-black'>$ 1.45M</div>
        </div>

        <div className='flex flex-col gap-2 bg-white p-2'>
          <div className='flex items-center space-x-1'>
            <div className='flex items-center rounded-md bg-green-100 px-2 py-0.5 text-green-800'>
              <div className='text-sm font-medium'>86%</div>
            </div>
            <div className='text-xs text-gray-400'>vs goal</div>
          </div>
          <div className='text-base font-semibold text-black'>$ 1.69M</div>
        </div>
        <div className='h-[0.5px] w-full bg-gray-200' />
        <div className='flex flex-col gap-2 bg-white p-2'>
          <div className='flex items-center space-x-1'>
            <div className='flex items-center rounded-md bg-red-100 px-2 py-0.5 text-red-800'>
              <div className='text-sm font-medium'>86%</div>
            </div>
            <div className='text-xs text-gray-400'>vs goal</div>
          </div>
          <div className='flex items-center space-x-1'>
            <div className='text-base font-semibold text-black'>$ 1.69M</div>
            <div className='text-xs text-gray-400'>on Jan 1 - 22</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default chartToolTip;
