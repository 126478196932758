import { agentActivityReport, dashboardReport } from '@/constants/endpoints';

import { APIClient } from '@/lib/ApiClient';

const apiClient = new APIClient();

export function fetchAgentActivityData(startDate, endDate, contactOption) {
  return apiClient.get(
    agentActivityReport,
    {
      from: startDate,
      to: endDate,
      ...(contactOption ? { contact: contactOption } : {}),
    },
    true
  );
}

export function fetchDashboardData(type, value) {
  return apiClient.get(
    dashboardReport,
    {
      type, //:'pre-defined',
      value, //: 'last-week',
    },
    true
  );
}
