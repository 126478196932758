import { useState } from 'react';
import useUserStore from '@/stores/UserStore';
import { toast } from 'sonner';
import { useShallow } from 'zustand/react/shallow';

import { deleteCannedMessage } from '@/hooks/user';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { ButtonLoading } from '@/components/common/button/loading-button';

const DeleteCannedMessageDialog = ({
  item,
  deleteModalStatus,
  setDeleteModalStatus,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const { removeCannedResponse } = useUserStore(
    useShallow((state) => ({
      removeCannedResponse: state.removeCannedResponse,
    }))
  );

  const onDelete = async () => {
    try {
      setIsLoading(true);
      await deleteCannedMessage(item?._id);
      removeCannedResponse(item?._id);

      setIsLoading(false);
      toast.success('Saved reply deleted successfully.');
      setDeleteModalStatus(false);
    } catch (error) {
      toast.error(error);
      setIsLoading(false);
    }
  };

  const renderContent = (currentContent) => {
    if (!currentContent?.content) return null;

    return (
      <>
        {currentContent.content.map((node, nodeIndex) => {
          if (node.type === 'paragraph') {
            if (!node.content || node.content.length === 0) {
              return <br key={nodeIndex} />;
            }

            return (
              <div key={nodeIndex}>
                {node.content?.map((item, itemIndex) => {
                  if (item.type === 'text') {
                    return <span key={itemIndex}>{item.text}</span>;
                  } else if (item.type === 'mention') {
                    return (
                      <code
                        key={itemIndex}
                        className='relative rounded-md bg-muted px-[0.3rem] py-[0.2rem] font-mono text-sm font-semibold'
                      >
                        {item.attrs.id}
                      </code>
                    );
                  } else if (item.type === 'hardBreak') {
                    return <br key={itemIndex} />;
                  }
                  return null;
                })}
              </div>
            );
          }
          return null;
        })}
      </>
    );
  };

  return (
    <Dialog open={deleteModalStatus} onOpenChange={setDeleteModalStatus}>
      <DialogContent className='sm:max-w-[425px]'>
        <DialogHeader>
          <DialogTitle>Delete Saved Replies</DialogTitle>
          <DialogDescription className='my-4'>
            Are you sure you want to delete this saved reply?
          </DialogDescription>
        </DialogHeader>

        <div className='space-y-4'>
          <div>
            <span className='text-sm font-semibold'>Shortcut:</span>{' '}
            <span>{item?.shortcut}</span>
          </div>
          <div>
            <span className='text-sm font-semibold'>Message:</span>
            <div className='mt-2 rounded-md border p-3'>
              {renderContent(item?.message)}
            </div>
          </div>
        </div>

        <DialogFooter>
          <Button
            variant='secondary'
            onClick={() => setDeleteModalStatus(false)}
          >
            Cancel
          </Button>
          {isLoading ? (
            <ButtonLoading isDestructive={true} />
          ) : (
            <Button variant='destructive' onClick={onDelete}>
              Delete
            </Button>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteCannedMessageDialog;
