import { useEffect, useState } from 'react';
import useTeamMemberStore from '@/stores/TeamMemberStore';
import { useShallow } from 'zustand/react/shallow';

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import AgentCreateForm from '@/components/register/AgentCreateForm';

const CreateAgentModal = () => {
  const { createAgentModalStatus, setCreateAgentModalStatus } =
    useTeamMemberStore(
      useShallow((state) => ({
        createAgentModalStatus: state.createAgentModalStatus,
        setCreateAgentModalStatus: state.setCreateAgentModalStatus,
      }))
    );

  return (
    <Dialog
      open={createAgentModalStatus}
      onOpenChange={setCreateAgentModalStatus}
    >
      <DialogContent className='sm:max-w-[425px]'>
        <DialogHeader>
          <DialogTitle>Create Agent</DialogTitle>
          <DialogDescription>
            Agents are users who can access chat conversations and reply to
            customers.
          </DialogDescription>
          <AgentCreateForm />
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

export default CreateAgentModal;
