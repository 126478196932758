import * as React from 'react';
import * as AvatarPrimitive from '@radix-ui/react-avatar';
import { VerifiedIcon, LinkIcon } from 'lucide-react'; // Import verified icon
import { cn } from '@/lib/utils';
import { Link } from 'react-router-dom';  // Add this import
import avatarIG from '@/assets/images/avatar-ig.png';
import avatarFB from '@/assets/images/avatar-fb.png';
import { Button } from '@/components/ui/button';

const Avatar = React.forwardRef(({ className, ...props }, ref) => (
  <AvatarPrimitive.Root
    ref={ref}
    className={cn(
      'relative flex h-8 w-8 shrink-0 overflow-hidden rounded-full',
      className
    )}
    {...props}
  />
));
Avatar.displayName = AvatarPrimitive.Root.displayName;

const AvatarImage = React.forwardRef(({ className, ...props }, ref) => (
  <AvatarPrimitive.Image
    ref={ref}
    className={cn('aspect-square h-full w-full', className)}
    {...props}
  />
));
AvatarImage.displayName = AvatarPrimitive.Image.displayName;

const AvatarFallback = React.forwardRef(({ className, ...props }, ref) => (
  <AvatarPrimitive.Fallback
    ref={ref}
    className={cn(
      'flex h-full w-full items-center justify-center rounded-full bg-avatar text-primary text-sm',
      className
    )}
    {...props}
  />
));
AvatarFallback.displayName = AvatarPrimitive.Fallback.displayName;

const InstagramVerifiedBadge = () => (
  <svg 
    aria-label="Verified" 
    className="x1lliihq x1n2onr6" 
    fill="rgb(0, 149, 246)" 
    height="18" 
    role="img" 
    viewBox="0 0 40 40" 
    width="18"
  >
    <title>Verified</title>
    <path 
      d="M19.998 3.094 14.638 0l-2.972 5.15H5.432v6.354L0 14.64 3.094 20 0 25.359l5.432 3.137v5.905h5.975L14.638 40l5.36-3.094L25.358 40l3.232-5.6h6.162v-6.01L40 25.359 36.905 20 40 14.641l-5.248-3.03v-6.46h-6.419L25.358 0l-5.36 3.094Zm7.415 11.225 2.254 2.287-11.43 11.5-6.835-6.93 2.244-2.258 4.587 4.581 9.18-9.18Z" 
      fillRule="evenodd"
    />
  </svg>
);

// New component for profile info
const AvatarProfile = React.forwardRef(({ 
  className,
  name,
  username,
  followers,
  following,
  isVerified,
  bio,
  channel,
  plan,
  external_url,  // Add external_url prop
  ...props 
}, ref) => (
  <div className={cn('flex flex-col gap-1 w-full', className)} {...props} ref={ref}>
    <div className="flex items-center gap-0">
      <span className="font-semibold text-sm truncate max-w-[210px]">
        {name}
      </span>
      {isVerified && (
        <span className='px-1 py-0'>
          <InstagramVerifiedBadge />
        </span>
      )}
    </div>
    <span className="text-gray-500 -mt-1">
      {channel === 'facebook' ? (
        <Button variant='link' size='sm' className='cursor-default px-0 h-6 py-0'>
          <img src={avatarFB} className='mr-2 aspect-square h-4 w-4' />
        </Button>
      ) : channel === 'instagram' ? (
        <Link
          to={`https://www.instagram.com/${username}`}
          target='_blank'
          className='-mt-1'
        >
          <Button variant='link' size='sm' className='px-0 h-6 py-0'>
            <img src={avatarIG} className='mr-2 aspect-square h-4 w-4' />
            {username}
          </Button>
        </Link>
      ) : null}
    </span>
    {bio && (
      <div className="break-words">
        <p className="text-xs text-muted-foreground whitespace-pre-wrap break-words">{bio}</p>
      </div>
    )}
    {external_url && (

      <div className="flex items-center gap-1">
        <LinkIcon className="h-3 w-3 text-blue-500" /> 
        <a 
          href={external_url}
          target="_blank"
          rel="noopener noreferrer"
          className="text-xs text-blue-500 hover:underline truncate"
          title={external_url}
        >
          {external_url}
        </a>
      </div>
    )}
    
    {(followers > 0 || following > 0) && (  // Only show if either followers or following exists
      <div className="flex gap-4">
        {followers > 0 && (  // Only show followers if it exists
          <span className="text-sm">
            <strong>{followers}</strong> Followers
          </span>
        )}
        {following > 0 && (  // Only show following if it exists
          <span className="text-sm">
            <strong>{following}</strong> Following
          </span>
        )}
      </div>
    )}
    {plan && (
      <div className='px-2'>
        <Button className='h-6 rounded-md px-2'>
          {plan.name} - ${plan.amount}
        </Button>
      </div>
    )}
  </div>
));
AvatarProfile.displayName = 'AvatarProfile';

export { Avatar, AvatarImage, AvatarFallback, AvatarProfile };