import { Loader2 } from 'lucide-react';

import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';

export function ButtonLoading({ isDestructive = false }) {
  return (
    <Button variant={cn(isDestructive ? 'destructive' : 'default')} disabled>
      <Loader2 className='mr-2 h-4 w-4 animate-spin' />
      Please wait
    </Button>
  );
}
