import { useEffect, useMemo, useState } from 'react';
import useTeamMemberStore from '@/stores/TeamMemberStore';
import { toast } from 'sonner';
import { useShallow } from 'zustand/react/shallow';

import { setLeadAllocation } from '@/hooks/agent';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { ScrollArea } from '@/components/ui/scroll-area';
import { ButtonLoading } from '@/components/common/button/loading-button';

const LeadAllocationDialog = ({
  leadAllocationModalStatus,
  setLeadAllocationModalStatus,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [allocationPercentages, setAllocationPercentages] = useState({});

  const { agents, updateAgentsLeadAllocation } = useTeamMemberStore(
    useShallow((state) => ({
      agents: state.agents,
      updateAgentsLeadAllocation: state.updateAgentsLeadAllocation,
    }))
  );

  const filteredAgents = useMemo(
    () => agents.filter((agent) => ['agent'].includes(agent.role)),
    [agents]
  );

  const initialAllocationPercentages = filteredAgents.reduce((acc, agent) => {
    acc[agent._id] = agent.lead_allocation_percentage || 0;
    return acc;
  }, {});

  useEffect(() => {
    setAllocationPercentages(initialAllocationPercentages);
  }, [filteredAgents]);

  const handlePercentageChange = (agentId, value) => {
    if (value < 0) {
      toast.error('Allocation percentage cannot be negative');
      return;
    }

    const newAllocationPercentages = {
      ...allocationPercentages,
      [agentId]: value,
    };

    const totalPercentage = Object.values(newAllocationPercentages).reduce(
      (acc, val) => acc + Number(val),
      0
    );

    if (totalPercentage > 100) {
      toast.error('Total allocation percentage cannot exceed 100%');
      return;
    }

    setAllocationPercentages(newAllocationPercentages);
  };

  const handleSplitEqually = () => {
    const equalPercentage = Math.floor(100 / filteredAgents.length);
    const remainingPercentage = 100 % filteredAgents.length;
    const newAllocationPercentages = filteredAgents.reduce(
      (acc, agent, index) => {
        acc[agent._id] =
          equalPercentage + (index < remainingPercentage ? 1 : 0);
        return acc;
      },
      {}
    );
    setAllocationPercentages(newAllocationPercentages);
  };

  const handleClose = () => {
    setAllocationPercentages(initialAllocationPercentages);
    setLeadAllocationModalStatus(false);
  };

  const handleSave = async () => {
    const totalPercentage = Object.values(allocationPercentages).reduce(
      (acc, val) => acc + Number(val),
      0
    );

    if (totalPercentage !== 100) {
      toast.error('Total allocation percentage must equal 100%');
      return;
    }

    setIsLoading(true);
    const allocationData = Object.entries(allocationPercentages).map(
      ([user_id, percentage]) => ({
        user_id,
        percentage,
      })
    );

    toast.promise(setLeadAllocation(allocationData), {
      loading: 'Saving lead allocation...',
      success: (response) => {
        const { allocations, message } = response;
        updateAgentsLeadAllocation(allocations);
        setLeadAllocationModalStatus(false);
        setIsLoading(false);
        return message;
      },
      error: () => {
        setIsLoading(false);
        return 'Unable to save the lead allocation';
      },
    });
  };

  return (
    <Dialog open={leadAllocationModalStatus} onOpenChange={handleClose}>
      <DialogContent className='sm:max-w-[425px]'>
        <DialogHeader>
          <DialogTitle>Lead Allocation</DialogTitle>
          <DialogDescription>
            Please select the lead distribution percentage for each of the
            agents listed below
          </DialogDescription>
        </DialogHeader>
        <div className='space-y-4'>
          <div className='mb-8 mt-4 flex flex-row justify-between'>
            <div>Total Allocation Remaining</div>
            <div className='flex w-24 items-center justify-center'>
              <span>
                {100 -
                  Object.values(allocationPercentages).reduce(
                    (acc, val) => acc + Number(val),
                    0
                  )}
                %
              </span>
            </div>
          </div>
          <ScrollArea type='always'>
            <div className='max-h-[450px]'>
              <ul className='space-y-2'>
                {filteredAgents.map((agent) => (
                  <li key={agent._id} className='flex justify-between'>
                    <span>
                      {agent.first_name} {agent.last_name} - {agent.role}
                    </span>
                    <div className='relative'>
                      <Input
                        type='number'
                        value={allocationPercentages[agent._id]}
                        onChange={(e) =>
                          handlePercentageChange(
                            agent._id,
                            Number(e.target.value)
                          )
                        }
                        className='w-24 px-[20px]'
                      />
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </ScrollArea>
        </div>
        <DialogFooter className='mt-5'>
          <div className='flex w-full flex-row items-center justify-between'>
            <div>
              <Button size='sm' variant='outline' onClick={handleSplitEqually}>
                Split Equally
              </Button>
            </div>
            <div className='flex space-x-2'>
              <Button variant='secondary' onClick={handleClose}>
                Cancel
              </Button>
              {isLoading ? (
                <ButtonLoading />
              ) : (
                <Button onClick={handleSave}>Save</Button>
              )}
            </div>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default LeadAllocationDialog;
