import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import avatarFB from '@/assets/images/avatar-fb.png';
import avatarIG from '@/assets/images/avatar-ig.png';
import useContactStore from '@/stores/ContactStore';
import useInboxStore from '@/stores/InboxStore';
import useUserStore from '@/stores/UserStore';
import { useHotkeys } from '@blueprintjs/core';
import { Loader2Icon } from 'lucide-react';
import { useInView } from 'react-intersection-observer';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';
import { useShallow } from 'zustand/react/shallow';

import {
  trackContactMessagesFocus,
  trackContatFBFetch,
  trackSendMessage,
} from '@/lib/analytics-event';
import { cn } from '@/lib/utils';
import {
  fetchContactMessages,
  fetchContactMessagesFB,
  sendContactFileAttachment,
  sendContactMessage,
  sendPrivateReplyToComment,
} from '@/hooks/contact';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import { ScrollArea } from '@/components/ui/scroll-area';
import TooltipComponent from '@/components/common/tooltip/tooltip';

import MessageComponent from './Message/MessageComponent';
import MessageInput from './MessageInput';

export default function ContactMessages() {
  const {
    page,
    setPage,
    messages,
    setMessages,
    setNewMessage,
    setPrevPageMessages,
    load_fb_past_messages,
    setLoadFbPastMessages,
    channel,
  } = useContactStore(
    useShallow((state) => ({
      page: state.page,
      setPage: state.setPage,
      messages: state.messages,
      setMessages: state.setMessages,
      setNewMessage: state.setNewMessage,
      setPrevPageMessages: state.setPrevPageMessages,
      load_fb_past_messages: state.load_fb_past_messages,
      setLoadFbPastMessages: state.setLoadFbPastMessages,
      channel: state.channel,
    }))
  );
  const scrollBottomDivRef = useRef(null);
  const scrollAreaDivRef = useRef(null);
  const previousScrollHeight = useRef(0);
  const [fbFetchLoading, setFbFetchLoading] = useState(false);

  const { owners, updateConversationNewMessage } = useInboxStore(
    useShallow((state) => ({
      owners: state.owners,
      updateConversationNewMessage: state.updateConversationNewMessage,
    }))
  );
  const currentUser = useUserStore((state) => state.user);
  const {
    contactId,
    fullname,
    profile_picture_url,
    updateCommentResponded,
    latest_message_received_timestamp,
    latest_comment_received_timestamp,
  } = useContactStore(
    useShallow((state) => ({
      contactId: state._id,
      fullname: state.fullname,
      profile_picture_url: state.profile_picture_url,
      updateCommentResponded: state.updateCommentResponded,
      latest_message_received_timestamp:
        state.latest_message_received_timestamp,
      latest_comment_received_timestamp:
        state.latest_comment_received_timestamp,
    }))
  );

  const { ref, inView } = useInView();

  const { id } = useParams();
  const defaultPic = `https://files.sbccrm.com/sbccrm/sbccrm/contacts/pics/no-pic.jpg`;

  const handleNextResponse = useCallback(
    (messages, page) => {
      setPrevPageMessages(messages);
      setPage(page);
    },
    [setPrevPageMessages, setPage]
  );

  const handleFetchFromFbResponse = (messages, load_fb_past_messages) => {
    setPrevPageMessages(messages);
    setLoadFbPastMessages(load_fb_past_messages);
  };

  const getOwnerDetails = (ownerId, type, channel) => {
    if (ownerId === '') {
      if (type === 'image') {
        return channel === 'instagram'
          ? avatarIG
          : channel === 'facebook'
            ? avatarFB
            : null;
      } else if (type === 'fullname' || type === 'initials') {
        return channel === 'instagram'
          ? 'IG'
          : channel === 'facebook'
            ? 'FB'
            : null;
      }
    }

    const currentOwner = owners.find((owner) => owner._id === ownerId);

    if (currentOwner) {
      if (type === 'initials') {
        return `${currentOwner.first_name.charAt(
          0
        )}${currentOwner.last_name.charAt(0)}`;
      } else if (type === 'image') {
        return currentOwner.profile_picture_url;
      } else if (type === 'fullname') {
        return `${currentOwner.first_name} ${currentOwner.last_name}`;
      }
    } else {
      return '';
    }
  };

  const fetchData = useCallback(
    async (contactId, type, page) => {
      // Inorder to keep syn between contactId and url, avoiding unnecessary fetch calls/page update
      if (contactId !== id) {
        // console.log('inside inview (check - failing)', contactId, id);

        return;
      }

      try {
        const response = await fetchContactMessages(contactId, page);
        const { data, next_page_url, current_page } = response;
        // console.log(
        //   'inside inview (next)',
        //   next_page_url ? current_page + 1 : null,
        //   contactId
        // );
        handleNextResponse(data, next_page_url ? current_page + 1 : null);
      } catch (error) {
        console.error('error fetching messages:', error);
      }
    },
    [handleNextResponse, id]
  );

  const fetchFromIG = async () => {
    try {
      setFbFetchLoading(true);
      const response = await fetchContactMessagesFB(contactId, page);
      trackContatFBFetch();

      const { data, load_fb_past_messages } = response;
      handleFetchFromFbResponse(data, load_fb_past_messages);
    } catch (error) {
      console.error('error fetching messages:', error);
    }
    setFbFetchLoading(false);
  };

  const scrollToBottom = () => {
    const scrollableBottomDiv = scrollBottomDivRef.current;
    if (scrollableBottomDiv) {
      scrollableBottomDiv.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const calculateDaysDiff = (timestamp) => {
    const providedDate = new Date(timestamp);
    const currentDate = new Date();
    const diffTime = currentDate - providedDate;
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const addMessage = async (message, type, options = null) => {
    var messageObj = null;
    var currentTimestamp = new Date().toISOString();

    switch (type) {
      case 'text':
        messageObj = {
          text: message,
          timestamp: currentTimestamp,
          direction: 'sent',
          user_id: currentUser._id,
          is_seen: null,
          is_deleted: null,
          reaction: null,
          emoji: null,
          message_type: 'text',
          media_url: null,
        };
        break;

      case 'image':
        messageObj = {
          timestamp: currentTimestamp,
          direction: 'sent',
          user_id: currentUser._id,
          is_seen: null,
          is_deleted: null,
          reaction: null,
          emoji: null,
          message_type: 'image',
          media_url: null,
        };
        break;

      case 'audio':
        messageObj = {
          timestamp: currentTimestamp,
          direction: 'sent',
          user_id: currentUser._id,
          is_seen: null,
          is_deleted: null,
          reaction: null,
          emoji: null,
          message_type: 'audio',
          media_url: null,
        };
        break;

      case 'video':
        messageObj = {
          timestamp: currentTimestamp,
          direction: 'sent',
          user_id: currentUser._id,
          is_seen: null,
          is_deleted: null,
          reaction: null,
          emoji: null,
          message_type: 'video',
          media_url: null,
        };
        break;

      case 'private_reply_comment':
        messageObj = {
          text: message,
          timestamp: currentTimestamp,
          direction: 'sent',
          user_id: currentUser._id,
          is_seen: null,
          is_deleted: null,
          reaction: null,
          emoji: null,
          message_type: 'text',
          media_url: null,
        };
        break;

      default:
        break;
    }

    try {
      let response;

      if (type === 'text') {
        console.log('currentUser', currentUser);
        if (
          currentUser?.customer?._id === '676abd064418d2a5550b7a63' ||
          currentUser?.customer?._id === '67504f4dcb556698690b0d32'
        ) {
          response = await toast.promise(
            (async () => {
              response = await sendContactMessage(contactId, message);
              setNewMessage({ ...messageObj, _id: response.message_id });
              updateConversationNewMessage(
                contactId,
                message,
                currentTimestamp
              );
              trackSendMessage(message.length, messageObj.message_type);
              return response;
            })(),
            {
              loading: 'Sending message...',
              success: 'Message sent successfully',
              error: (error) => error || 'Failed to send the message (#900)',
            }
          );
        } else {
          if (
            latest_message_received_timestamp &&
            calculateDaysDiff(latest_message_received_timestamp) >= 7
          ) {
            toast.error(
              'You cannot send a message outside the 7 day window unless they respond back'
            );
            return;
          }

          response = await sendContactMessage(contactId, message);
          setNewMessage({ ...messageObj, _id: response.message_id });
          updateConversationNewMessage(contactId, message, currentTimestamp);
          trackSendMessage(message.length, messageObj.message_type);
        }
      } else if (type === 'image' || type === 'audio' || type === 'video') {
        if (
          latest_message_received_timestamp &&
          calculateDaysDiff(latest_message_received_timestamp) >= 7
        ) {
          toast.error(
            'You cannot send a message outside the 7 day window unless they respond back'
          );
          return;
        }
        const formData = new FormData();
        formData.append('file', message);

        response = await sendContactFileAttachment(contactId, formData);

        setNewMessage({
          ...messageObj,
          _id: response.message_id,
          media_url: response.media_url,
        });
        const customMessage =
          type.charAt(0).toUpperCase() + type.slice(1) + ' message';
        updateConversationNewMessage(
          contactId,
          customMessage,
          currentTimestamp
        );

        // TODO: Write mixpanel event for file attachment
      } else if (type === 'private_reply_comment') {
        if (
          latest_comment_received_timestamp &&
          calculateDaysDiff(latest_comment_received_timestamp) >= 7
        ) {
          toast.error(
            'Comments which are older than 7 days cannot be private replied'
          );
          return;
        }
        const { sbcMessageId, commentText } = options;
        response = await sendPrivateReplyToComment(
          contactId,
          message,
          sbcMessageId
        );
        setNewMessage({
          ...messageObj,
          _id: response.message_id,
          private_reply_comment: true,
          private_reply_parent_data: {
            sbc_msg_id: sbcMessageId,
            text: commentText,
          },
        });
        updateConversationNewMessage(contactId, message, currentTimestamp);
        updateCommentResponded(sbcMessageId);
        // TODO: Write mixpanel event for private reply comment
      }
      return response;
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    if (scrollAreaDivRef.current) {
      // Calculate the height difference of the scroll content
      const scrollHeightDifference =
        scrollAreaDivRef.current.scrollHeight - previousScrollHeight.current;

      // Set the scroll position to maintain the current visible position
      scrollAreaDivRef.current.scrollTop += scrollHeightDifference;

      // Save the new scroll height
      previousScrollHeight.current = scrollAreaDivRef.current.scrollHeight;
    }
  }, [messages]); // Dependency array

  // useEffect(() => {
  //   console.log('inside inview (page)', messageLoading, page, contactId);
  // }, [page]);

  useEffect(() => {
    if (contactId) {
      previousScrollHeight.current = 0;
      scrollToBottom();
    }
  }, [contactId]);

  useEffect(() => {
    // console.log('outside inview', messageLoading, page, contactId);
    if (inView) {
      // console.log('inside inview', messageLoading, page, contactId);
      fetchData(contactId, 'next', page);
    }
  }, [inView]);

  const focusAndScroll = useCallback(() => {
    const element = scrollAreaDivRef.current;

    if (element) {
      element.focus();
      trackContactMessagesFocus('keyboard');

      // Add keydown event listener for arrow keys
      const handleArrowKeys = (event) => {
        switch (event.key) {
          case 'ArrowUp':
            element.scrollBy(0, -10);
            break;
          case 'ArrowDown':
            element.scrollBy(0, 10);
            break;
          default:
            break;
        }
      };

      element.addEventListener('keydown', handleArrowKeys);

      // Remove the event listener on blur
      element.onblur = () => {
        element.removeEventListener('keydown', handleArrowKeys);
      };
    }
  }, []);

  const hotkeys = useMemo(
    () => [
      {
        combo: 'alt+c',
        global: true,
        group: 'Contact Chat',
        label: 'Scroll Contact Messages',
        onKeyUp: focusAndScroll,
      },
    ],
    [focusAndScroll]
  );

  const handleEscapeKeyPress = useCallback((e) => {
    if (e.key === 'Escape') {
      scrollAreaDivRef?.current.blur();
    }
  }, []);

  const { handleKeyUp } = useHotkeys(hotkeys);

  return (
    <>
      <div className='flex min-h-0 flex-shrink flex-grow flex-col-reverse justify-end'>
        {contactId && (
          <ScrollArea
            ref={scrollAreaDivRef}
            onKeyUp={handleKeyUp}
            showRing={true}
            onKeyDown={handleEscapeKeyPress}
            onMouseDown={() => trackContactMessagesFocus('mouse')}
          >
            {page !== null && page !== 0 && (
              <div ref={ref} className='flex items-center justify-center py-3'>
                <Loader2Icon className='h-4 w-4 animate-spin' />
              </div>
            )}
            <>
              {channel === 'instagram' ? (
                page === null ? (
                  load_fb_past_messages === true ? (
                    <div className='my-3 flex items-center justify-center'>
                      {fbFetchLoading ? (
                        <Loader2Icon className='h-4 w-4 animate-spin' />
                      ) : (
                        <Button size='xs' onClick={fetchFromIG}>
                          Fetch from IG
                        </Button>
                      )}
                    </div>
                  ) : (
                    <div className='relative mx-10 my-5 flex items-center justify-center'>
                      <div className='absolute  left-0 right-0 -z-10 w-full border-t bg-primary' />
                      <span className='rounded-md bg-border px-4 py-2 text-xs'>
                        This is the start of conversation
                      </span>
                    </div>
                  )
                ) : null
              ) : null}
              {messages.map((message, index) => {
                // Check if the current message has the same user ID as the previous message
                const sameUserAsPrevious =
                  index > 0 && messages[index - 1].user_id === message.user_id;

                // Check if it's a new day compared to the previous message
                const isNewDay =
                  index > 0 &&
                  new Date(
                    messages[index - 1].timestamp
                  ).toLocaleDateString() !==
                    new Date(message.timestamp).toLocaleDateString();

                // Check if the message is within the last 5 days
                const isWithinLast5Days =
                  new Date() - new Date(message.timestamp) <
                  5 * 24 * 60 * 60 * 1000;

                // Render timestamp message if it's a new day and not the first message
                const timestampMessage =
                  isNewDay && index > 0 ? (
                    <div
                      key={`timestamp_${message.timestamp}`}
                      className='my-4 text-center text-xs text-muted-foreground'
                    >
                      {isWithinLast5Days
                        ? `${new Date(message.timestamp).toLocaleDateString(
                            'en-US',
                            {
                              weekday: 'short',
                            }
                          )} ${new Date(message.timestamp).toLocaleTimeString(
                            'en-US',
                            {
                              hour: '2-digit',
                              minute: '2-digit',
                            }
                          )}`
                        : `${new Date(message.timestamp).toLocaleDateString(
                            'en-US',
                            {
                              day: 'numeric',
                              month: 'short',
                            }
                          )} at ${new Date(
                            message.timestamp
                          ).toLocaleTimeString('en-US', {
                            hour: '2-digit',
                            minute: '2-digit',
                          })}`}
                    </div>
                  ) : null;

                return (
                  <div key={message._id}>
                    {timestampMessage}
                    <div
                      className={cn(
                        'mx-4 flex items-end space-x-2',
                        message.direction === 'sent' ? 'ml-auto' : 'mr-auto',
                        sameUserAsPrevious
                          ? message.emoji
                            ? 'mb-5 mt-3'
                            : 'my-3'
                          : 'my-3',
                        index === messages.length - 1 ? 'mb-6' : '' // Add extra bottom margin to the last item
                      )}
                    >
                      {message.direction !== 'sent' && ( // this line ensures the avatar is on the right for non-user messages
                        <TooltipComponent
                          content={<span>{fullname}</span>}
                          delayDuration='100'
                        >
                          <div>
                            <Avatar>
                              {profile_picture_url ? (
                                <AvatarImage src={profile_picture_url} />
                              ) : (
                                <AvatarImage src={defaultPic} />
                              )}
                              <AvatarFallback>
                                <AvatarImage src={defaultPic} />
                                {fullname[0]}
                              </AvatarFallback>
                            </Avatar>
                          </div>
                        </TooltipComponent>
                      )}
                      {
                        <TooltipComponent
                          content={
                            <span>
                              {new Date(message.timestamp).toLocaleString(
                                'en-US'
                              )}
                            </span>
                          }
                          delayDuration='100'
                        >
                          <div
                            className={cn(
                              'relative flex max-w-[75%] flex-col gap-2 self-center whitespace-pre-wrap',
                              message.direction === 'sent' ? 'ml-auto' : ''
                            )}
                          >
                            <MessageComponent message={message} />
                          </div>
                        </TooltipComponent>
                      }

                      {message.direction === 'sent' && ( // this line ensures the avatar is on the left for user messages
                        <TooltipComponent
                          content={
                            <span>
                              {getOwnerDetails(
                                message.user_id,
                                'fullname',
                                message.channel
                              )}
                            </span>
                          }
                          delayDuration='100'
                        >
                          <div>
                            <Avatar>
                              <AvatarImage
                                src={getOwnerDetails(
                                  message.user_id,
                                  'image',
                                  message.channel
                                )}
                              />
                              <AvatarFallback>
                                {getOwnerDetails(
                                  message.user_id,
                                  'initials',
                                  message.channel
                                )}
                              </AvatarFallback>
                            </Avatar>
                          </div>
                        </TooltipComponent>
                      )}
                    </div>
                  </div>
                );
              })}
            </>
            {messages.length ? (
              <div ref={scrollBottomDivRef} className='h-1' />
            ) : null}
          </ScrollArea>
        )}
      </div>
      <MessageInput addMessage={addMessage} />
    </>
  );
}
